import React from 'react'
import { useAppContext } from '../../../contexts/App'
import { formatCurrency } from '../../../libs/helpers/formatCurrency'


export default function Price({ amount, discount, total, bonus, deliveryAmount }: any) {
  const {branch} = useAppContext()
  return (
    <div className=''>
      <div className='flex flex-col gap-4 pb-5 border-b-[1px] border-gray-30/40 text-dark dark:text-light'>
        <div className={`flex items-center justify-between text-2xl font-bold -tracking-[.01em]`}>
          <div>Сумма:</div>
          <div className={'flex items-center gap-[6px]'}>{formatCurrency(branch.extended.currency.code, amount)}</div>
        </div>
        {
          !!discount && 
          <div className='flex items-center justify-between text-xl font-bold -tracking-[.01em] text-gray-40 dark:text-gray-30'>
            <div>Скидка:</div>
            <div className={'flex items-center gap-[6px]'}>{formatCurrency(branch.extended.currency.code, discount)}</div>
          </div>
        }

        {
          !!bonus && 
          <div className='flex items-center justify-between text-xl font-medium -tracking-[.01em] text-gray-40 dark:text-gray-30'>
            <div>Списано баллов:</div>
            <div className={'flex items-center gap-[6px]'}>{ Math.floor(bonus) }</div>
          </div>
        }

        {
          !!deliveryAmount && 
          <div className='flex items-center justify-between text-xl font-medium -tracking-[.01em] text-gray-40 dark:text-gray-30'>
            <div>Стоимость доставки:</div>
            <div className={'flex items-center gap-[6px]'}>{ formatCurrency(branch.extended.currency.code, deliveryAmount) }</div>
          </div>
        }
      </div>

      <div className={`flex items-center justify-between text-3xl font-bold -tracking-[.01em] mt-4`}>
        <div className={'text-main'}>Итого:</div>
        <div className={'flex items-center gap-[6px] text-dark dark:text-light'}>{formatCurrency(branch.extended.currency.code, total)}</div>
      </div>
    </div>
  )
}
