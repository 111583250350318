import React, { createContext, useContext, useMemo } from 'react'
import { useCartContext } from './CartContext'
import { useEffect } from 'react';
import useForm from '../hooks/useForm';
import { AdditionalFieldType } from '../types/cart';
import { TextInput } from '../components/common/form/input/TextInput';
import InputSelector from '../components/common/form/input/InputSelector';
import Checkbox from '../components/common/form/checkbox';
import Textarea from '../components/common/form/textarea';

interface ContextType {
  data: any;
  updateField: (name: string, value: any) => void;
  vlidateCartFields: () => boolean;
  errors: any;
  updateErrors: (value: any) => void;
  synchronizationWithCart: () => void;
  resetForm: () => void;
}

const AdditionalFieldsFunnelsContext = createContext<ContextType>({
  data: {},
  updateField: () => {}, 
  vlidateCartFields: () => false,
  errors: {},
  updateErrors: () => {}, 
  synchronizationWithCart: () => {},
  resetForm: () => {},
})

export function AdditionalFieldsFunnelsContextWrapper({ children }: {children: React.ReactNode}) {
  const { data, updateData, updateField, changedFields, changedFieldsRefresh, errors, updateErrors } = useForm({})
  const { cart, updateCartDebounce } = useCartContext()

  function vlidateCartFields() {
    let errs: any = {}
    cart.additionalFields.filter(additionalField => ['top-in-cart', 'bottom-in-cart'].includes(additionalField.place) && additionalField.isRequired).forEach((additionalField) => {
      if (!data[additionalField.name]) {
        errs[additionalField.name] = ['Поле обязательно для заполнения.']
      }
    })
    updateErrors(errs)

    return !Object.keys(errs).length
  }

  useEffect(() => {
    if (Object.values(changedFields).length) {
      updateCartDebounce({additionalFields: changedFields})
    }
  }, [changedFields])

  const synchronizationWithCart = () => {
    updateData(cart.additionalFields.reduce((acc, item) => ({...acc, [item.name]: item.value}), {}))
  }

  return (
    <AdditionalFieldsFunnelsContext.Provider value={{data, updateField, vlidateCartFields, errors, updateErrors, synchronizationWithCart, resetForm: changedFieldsRefresh}}>
      { children }
    </AdditionalFieldsFunnelsContext.Provider>
  )
}

export function useAdditionalFieldsFunnelsContext() {
  return useContext(AdditionalFieldsFunnelsContext)
}

export function AdditionalFields({ place='top-in-cart', className='' }) {
  const { cart } = useCartContext()

  const { synchronizationWithCart } = useAdditionalFieldsFunnelsContext()

  useEffect(() => {
    synchronizationWithCart()
  }, [])
  
  const fields = useMemo(() => {
    return cart.additionalFields.filter(additionalField => additionalField.place === place)
  }, [cart.additionalFields, place])
  
  return (
    <div className={`grid grid-cols-1 gap-[20px] ${fields.length ? className : ''}`}>
      {
        cart.additionalFields.filter(additionalField => additionalField.place === place).map(additionalField => (
          <AdditionalField additionalField={additionalField}/>
        ))
      }
    </div>
  )
}

function AdditionalField({ additionalField } : {additionalField: AdditionalFieldType}) {
  const { data, updateField, errors } = useAdditionalFieldsFunnelsContext()

  switch(additionalField.type) {
    case 'text': 
      return (
        <div>
          <div className='text-[20px] font-medium mb-5 text-main'>{ additionalField.label }<span className='text-main'>{additionalField.isRequired ? '*' : ''}</span></div>

          <TextInput
            value={data[additionalField.name] || ''}
            onChange={(value) => updateField(additionalField.name, value)}
            placeholderInput={`${additionalField.label}`}
            className='h-10'
            name={additionalField.name}
            errors={errors}
          />
          
          {additionalField.description && <div className='mt-2 text-xs text-gray-40 dark:text-gray-30'>{ additionalField.description }</div>}
          
          {
            additionalField.links.map(({title, url}) => {
              if (url) {
                return (
                  <a key={title} className='text-main text-xs underline mr-2' href={url} target='_blank'>{ title }</a>
                )
              }

              return <span key={title} className='text-xs mr-2'>{ title }</span>
            })
          }
        </div>
      )
    case 'select':
      if (!data[additionalField.name] && data[additionalField.name] != null && additionalField?.items?.length) {
        updateField(additionalField.name, null)
      }
      return (
        <div>
          <div className='text-[20px] font-medium mb-5 text-main'>{ additionalField.label }<span className='text-main'>{additionalField.isRequired ? '*' : ''}</span></div>

          <InputSelector
            variants={additionalField?.items?.length ? additionalField?.items.map(({title, value}) => ({name: title, id: value})) : []}
            value={data[additionalField.name]}
            onChange={(value: any) => {updateField(additionalField.name, value)}}   
            placeholder='Выберите значение'
            name={additionalField.name}
            errors={errors}
          />

          {additionalField.description && <div className='mt-2 text-xs text-gray-40 dark:text-gray-30'>{ additionalField.description }</div>}
        
          {
            additionalField.links.map(({title, url}) => {
              if (url) {
                return (
                  <a key={title} className='text-main text-xs underline mr-2' href={url} target='_blank'>{ title }</a>
                )
              }

              return <span key={title} className='text-xs mr-2'>{ title }</span>
            })
          }
        </div>
      )
    case 'boolean':
      return (
        <div>
          {/* <div className='text-xl font-bold mb-2'>{ additionalField.label }<span className='text-main'>{additionalField.isRequired ? '*' : ''}</span></div> */}

          <Checkbox
            label={<span>{additionalField.label}<span className='text-main'>{additionalField.isRequired ? '*' : ''}</span></span>}
            value={data[additionalField.name]}
            onChange={(value: any) => {updateField(additionalField.name, value)}}
            name={additionalField.name}
            errors={errors}
          />
          
          {additionalField.description && <div className='mt-5 text-xs text-gray-40 dark:text-gray-30'>{ additionalField.description }</div>}
        
          {
            additionalField.links.map(({title, url}) => {
              if (url) {
                return (
                  <a key={title} className='text-main text-xs underline mr-2' href={url} target='_blank'>{ title }</a>
                )
              }

              return <span key={title} className='text-xs mr-2'>{ title }</span>
            })
          }
        </div>
      )
    case 'textarea':
      return (
        <div>
          <div className='text-[20px] font-medium mb-5 text-main'>{ additionalField.label }<span className='text-main'>{additionalField.isRequired ? '*' : ''}</span></div>
          
          <Textarea
            value={data[additionalField.name] || ''}
            onChange={(value: any) => updateField(additionalField.name, value)}
            placeholder={`${additionalField.label}`}
            name={additionalField.name}
            errors={errors}
          />

          {additionalField.description && <div className='mt-2 text-xs text-gray-40 dark:text-gray-30'>{ additionalField.description }</div>}
        
          {
            additionalField.links.map(({title, url}) => {
              if (url) {
                return (
                  <a key={title} className='text-main text-xs underline mr-2' href={url} target='_blank'>{ title }</a>
                )
              }

              return <span key={title} className='text-xs mr-2'>{ title }</span>
            })
          }
        </div>
      )
  }
}