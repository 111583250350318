export default function TelegramRoundIcon({
  width = "63",
  height = "62"
}: {
  width?: string;
  height?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 63 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.0292969" width="62" height="62" rx="31" className='fill-main' />
      <path
        d="M42.5663 18.3289C42.0299 17.9146 41.1873 17.8553 40.3161 18.1739H40.3146C39.3984 18.5087 14.3781 28.2831 13.3595 28.6824C13.1743 28.7411 11.5563 29.2909 11.723 30.5156C11.8717 31.6198 13.1721 32.0772 13.3309 32.1299L19.6918 34.1136C20.1139 35.393 21.6696 40.1135 22.0136 41.1219C22.2282 41.7506 22.578 42.5766 23.191 42.7466C23.7289 42.9355 24.2639 42.7629 24.6101 42.5154L28.499 39.2301L34.777 43.6893L34.9265 43.7707C35.3528 43.9427 35.7612 44.0287 36.151 44.0287C36.4522 44.0287 36.7411 43.9772 37.0172 43.8743C37.9578 43.5225 38.3341 42.7062 38.3734 42.6137L43.0627 20.4142C43.3488 19.2286 42.9512 18.6253 42.5663 18.3289ZM25.3017 34.9071L23.1559 40.1187L21.0101 33.6042L37.4614 22.5295L25.3017 34.9071Z"
        fill="white"
      />
    </svg>
  );
}
