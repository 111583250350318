export default function YandexIcon({
  width = "14",
  height = "14"
}: {
  width?: string;
  height?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.62734 4.45342C6.83603 4.4141 7.05367 4.39443 7.28025 4.39443C7.41739 4.39443 7.53664 4.39771 7.638 4.40426C7.74533 4.40426 7.83775 4.40754 7.91527 4.4141C8.0047 4.42065 8.08222 4.42393 8.14781 4.42393V6.99006H7.25342C6.68697 6.99006 6.26064 6.8983 5.97443 6.71477C5.69419 6.52468 5.55407 6.18712 5.55407 5.70208C5.55407 5.44645 5.60177 5.23342 5.69717 5.063C5.79257 4.89258 5.92077 4.75821 6.08176 4.6599C6.24275 4.56158 6.42461 4.49275 6.62734 4.45342Z"
        fill="#FF3D22"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.10327 13.5C10.6931 13.5 13.6033 10.5899 13.6033 7C13.6033 3.41015 10.6931 0.5 7.10327 0.5C3.51342 0.5 0.603271 3.41015 0.603271 7C0.603271 10.5899 3.51342 13.5 7.10327 13.5ZM7.75428 3.56855C7.61117 3.562 7.47701 3.55872 7.3518 3.55872C6.93441 3.55872 6.55877 3.6046 6.22486 3.69637C5.89096 3.78813 5.60475 3.92578 5.36625 4.10931C5.1337 4.28628 4.95482 4.50914 4.82961 4.77788C4.70439 5.04662 4.64179 5.35796 4.64179 5.71191C4.64179 6.02653 4.68054 6.29855 4.75806 6.52796C4.84154 6.75082 4.94588 6.93763 5.0711 7.08838C5.19631 7.23914 5.33345 7.35712 5.48252 7.44233C5.63158 7.52754 5.77469 7.59309 5.91183 7.63897C5.81642 7.77662 5.70313 7.95359 5.57196 8.1699C5.44674 8.37964 5.31258 8.60906 5.16948 8.85813C5.03234 9.10721 4.89818 9.36939 4.767 9.64469C4.63582 9.91998 4.52254 10.1854 4.42713 10.4411H5.33941C5.44078 10.1986 5.55109 9.94948 5.67034 9.69385C5.79555 9.43822 5.92077 9.19242 6.04598 8.95645C6.1712 8.72048 6.29641 8.49763 6.42163 8.28788C6.54684 8.07813 6.66312 7.8946 6.77044 7.73729C6.92547 7.75695 7.0477 7.77006 7.13714 7.77662H8.14781V10.4411H9.01537V3.72586C8.89612 3.69309 8.76494 3.66687 8.62184 3.64721C8.47873 3.62754 8.33265 3.61116 8.18358 3.59805C8.04048 3.58494 7.89738 3.5751 7.75428 3.56855Z"
        fill="#FF3D22"
      />
    </svg>
  );
}
