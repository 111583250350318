import React, { useRef, useState, useEffect, useMemo } from 'react'
import Slider from "react-slick";
import { useAppContext } from '../../../../contexts/App';
import { apiClient } from '../../../../libs/api/apiClient';
import { useProductDetailCardContext } from '../../../../contexts/ProductDetailCardContext';
import { formatCurrency } from '../../../../libs/helpers/formatCurrency';
import { resizeImages } from '../../../../libs/helpers/resizeImage';
import CrossedOldPriceIcon from '../../../icons/CrossedOldPriceIcon';
import productImgStub from "../../../../images/productImgStub.jpg";

export default function Popular() {
  const { branch } = useAppContext()
  const [popularProducts, setPopularProducts] = useState([])
  const [isSwiping, setIsSwiping] = useState(false);

  const { openDetail } = useProductDetailCardContext()

  function getPopularProducts() {
    apiClient.catalog.getPopular(branch.id).then(({data}) => {
      setPopularProducts(data)
      return data
    })
  }

  useEffect(() => {
    if (branch.id) {
      getPopularProducts()
    }
  }, [branch.id])

  const products = useMemo(() => {
      if (popularProducts) {
        return popularProducts.map((p: any) => {
          p.priceString = formatCurrency(branch.extended.currency.code, p.price)
          p.oldPriceString = formatCurrency(branch.extended.currency.code, p.oldPrice)
          p.imageResize = resizeImages(p.image, [125, 300, 400], ['webp', 'jpg'])
          return p  
        })
      }

      return []
  }, [popularProducts]);

  const settingsSlider = {
    slidesToShow: products.length < 8 ? products.length : 8,
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToScroll: 8,
    variableWidth: true,
    beforeChange: () => setIsSwiping(true),
    afterChange: () => setTimeout(() => setIsSwiping(false), 100),
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: products.length < 7 ? products.length : 7,
          slidesToScroll: 7,
        }
      },
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: products.length < 6 ? products.length : 6,
          slidesToScroll: 6,
        }
      },
      {
        breakpoint: 1120,
        settings: {
          slidesToShow: products.length < 5 ? products.length : 5,
          slidesToScroll: 5,
        }
      },
      {
        breakpoint: 840,
        settings: {
          slidesToShow: products.length < 4 ? products.length : 4,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: products.length < 3 ? products.length : 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 510,
        settings: {
          slidesToShow: products.length < 2 ? products.length : 2,
          slidesToScroll: 2,
        }
      },
    ]
  };

  const slider = useRef<any>(null)

  return (
    <div className='mt-[30px]'>
      {!!popularProducts?.length && <div className={`text-dark dark:text-light text-4xl font-bold`}>Популярное</div>}
      
      <div className={`relative mt-1`}>
        <div className='absolute w-[1000px] h-[100%] left-[-1000px] z-[100] cursor-pointer' onClick={() => slider.current?.slickPrev()}/>
        <Slider {...settingsSlider} ref={slider} className='popular-slider -mx-[8px]'>
          {
            products?.map((product: any) => (
              <div
                key={product?.id}
                className="product flex items-center justify-center cursor-pointer mx-[8px] slide"
                onClick={() => !isSwiping && openDetail(product?.id)}
              >
                <div className="mb-10 mt-[30px] flex min-h-[233px] w-[147px] cursor-pointer flex-col items-stretch justify-between rounded-[29px] bg-white p-[12px] shadow-lg dark:bg-gray-50">
                  {product.image ? (
                    <div className="flex h-[123px] w-full flex-shrink-0 items-center justify-center overflow-hidden rounded-2xl">
                      <picture>
                        <source
                          type="image/webp"
                          srcSet={`${product.imageResize.webp[125]} 1x, ${product.imageResize.webp[300]} 2x, ${product.imageResize.webp[400]} 3x`}
                        />
                        <source
                          type="image/jpeg"
                          srcSet={`${product.imageResize.jpg[125]} 1x, ${product.imageResize.jpg[300]} 2x, ${product.imageResize.jpg[400]} 3x`}
                        />
                        <img
                          className="h-full w-full rounded-[17px] object-cover"
                          src={product.imageResize.jpg[125]}
                          alt=""
                        />
                      </picture>
                    </div>
                  ) : (
                    <div className="flex h-[123px] w-full flex-shrink-0 items-center justify-center overflow-hidden rounded-[17px]">
                      <img
                        className="h-full w-full rounded-[17px] object-cover"
                        src={productImgStub}
                        width={125}
                        height={125}
                        alt=""
                      />
                    </div>
                  )}

                  <div className="mt-[10px] flex flex-col justify-end">
                    <div className="whitespace-normal text-center text-[16px] font-normal leading-[127.02%] -tracking-[.01em] text-dark dark:text-white sm:text-base sm:font-bold">
                      {product.title}
                    </div>

                    <div className="flex flex-row items-center justify-center">
                      {!!product.oldPrice && !product?.optionsExists && (
                        <div
                          className={`relative ${
                            String(product.oldPrice).length >= 4
                              ? "xs:w-[31%]"
                              : "xs:max-w-[21%]"
                          } ml-[2px] mr-2 w-[30%] text-sm leading-[17px] text-gray-50 dark:text-gray-30`}
                        >
                          <div className="flex items-center opacity-60">
                            {product.oldPriceString}
                          </div>
                          <div className="absolute left-0 top-0 h-full w-full">
                            <CrossedOldPriceIcon
                              colorClassName="stroke-main"
                              width={
                                window.innerWidth > 425 ? "100%" : "100%"
                              }
                              height={"auto"}
                            />
                          </div>
                        </div>
                      )}
                      <div className="flex items-center gap-1 text-xl font-bold text-main">
                        {product?.optionsExists && "От"} {product.priceString}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          }
        </Slider> 
        <div className='absolute w-[1000px] h-[100%] right-[-1000px] top-0 z-[100] cursor-pointer' onClick={() => slider.current?.slickNext()}/>
      </div>
    </div>
  )
}
