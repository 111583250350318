import React, { useEffect, useMemo, useRef, useState } from 'react'
import Slider from "react-slick";
import { useCartContext } from '../../../../contexts/CartContext';
import { useAppContext } from '../../../../contexts/App';
import { resizeImage } from '../../../../libs/helpers/resizeImage';
import productImgStub from '../../../../images/productImgStub.jpg'
import { Preloader } from '../../../common/preloader';
import ArrowIcon from '../../../icons/ArrowIcon';


export default function GiftСhoice() {
  const { cart } = useCartContext()
  const { allProducts } = useAppContext()

  const countChosenGifts = useMemo(() => {
    return cart.products.filter(p => p.isChosenGift).length
  }, [JSON.stringify(cart.products)])

  const giftsForChoose = useMemo(() => {
    return cart.options.giftsForChoose.filter((p: any) => allProducts[p.goodId]).map((p: any) => ({...allProducts[p.goodId], comment: p?.comment}))   
  }, [JSON.stringify(cart.options.giftsForChoose)])

  const slider = useRef<any>(null);

  const settingsSlider = {
    slidesToShow: cart.options.giftsForChoose.length < 5 ? cart.options.giftsForChoose.length : 5,
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToScroll: 5,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 880,
        settings: {
          slidesToShow: cart.options.giftsForChoose.length < 4 ? cart.options.giftsForChoose.length : 4,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 712,
        settings: {
          slidesToShow: cart.options.giftsForChoose.length < 3 ? cart.options.giftsForChoose.length : 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 540,
        settings: {
          slidesToShow: cart.options.giftsForChoose.length < 2 ? cart.options.giftsForChoose.length : 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  if (!cart.options.giftsForChoose.length) {
    return <></>
  }

  return (
    <div className={`md:pt-[15px] rounded-b-[29px]`}>
      <div className={'flex items-center justify-between mt-[15px]'}>
        <div className='text-xl -tracking-[.01em] font-bold mb-5 text-gray-60 dark:text-white'>Подарок на выбор</div>

        {cart.options.maxChosenGifts > 1 && <div className='text-xl -tracking-[.01em] font-bold text-main'>{countChosenGifts} / {cart.options.maxChosenGifts}</div>}
      </div>

      <div className={`relative`}>
        <div className='absolute w-[1000px] h-[100%] left-[-1000px] z-[100] cursor-pointer' onClick={() => slider.current?.slickPrev()}/>
        <Slider 
          ref={slider}
          {...settingsSlider}
          className='chosen-gifts-slider -mx-[10px]'
        >
          {
            giftsForChoose.map((product: any) => (
              <div className='mx-[10px] h-full slide' key={product.id}>
                <Product product={product} maxChosenGifts={cart.options.maxChosenGifts == countChosenGifts}/>
              </div>
            ))
          }
        </Slider>
        <div className='absolute w-[1000px] h-[100%] right-[-1000px] top-0 z-[100] cursor-pointer' onClick={() => slider.current?.slickNext()}/>
      </div>
    </div>
  )
}

function Product({ product, maxChosenGifts }: any) {
  const { company } = useAppContext()
  const { cart, addProductServer, updateProductServer } = useCartContext()
  const [load, setLoad] = useState(false)

  const productCart = useMemo(() => {
    return cart.products.filter(p => p.isChosenGift && p.productId == product.id)[0]
  }, [JSON.stringify(cart.products)])

  return (
    <div onClick={() => {}} className='p-[12px] flex flex-col gap-[10px] w-[154px] bg-white shadow-lg dark:bg-gray-50 rounded-[15px]'>
      {
        product.image ?
        <div className='rounded-[17px] overflow-hidden h-[130px] min-w-[130px]'>
          <picture className={'h-[130px]'}>
            <source className={'h-[130px]'} type="image/webp" srcSet={`${resizeImage(product.image, '125', 'webp')} 1x, ${resizeImage(product.image, '300', 'webp')} 2x, ${resizeImage(product.image, '400', 'webp')}3x`}/>    
            <source className={'h-[130px]'} type="image/jpeg" srcSet={`${resizeImage(product.image, '125', 'jpg')} 1x, ${resizeImage(product.image, '300', 'jpg')} 2x, ${resizeImage(product.image, '400', 'jpg')} 3x`}/>
            <img 
              className={'h-[130px]'}
              src={resizeImage(product.image, '125', 'jpg')} 
              srcSet={`${resizeImage(product.image, '125', 'jpg')} 1x, ${resizeImage(product.image, '300', 'jpg')} 2x, ${resizeImage(product.image, '400', 'jpg')} 3x`}
              alt=""
            />
          </picture>
        </div> :
        <img
          className='w-[130px] h-[130px] rounded-[17px]'
          src={productImgStub} 
          alt=""
        />
      }


      <div className='-tracking-[.01em] font-medium lines-ellipsis lines-ellipsis--2 text-center h-9 leading-[18px] text-gray-60 dark:text-white'>{ product.title }</div>

      <>          
        {
          productCart ?
          <div 
            onClick={event => {
              event.stopPropagation()
              setLoad(true)
              updateProductServer(productCart.uid, {count: 0}).then(() => setLoad(false))
            }} 
            className='w-full h-[34px] flex items-center justify-center rounded-[18px] font-bold cursor-pointer bg-orderbtn text-main'
          >
            {load ? 
              <Preloader countOfDot={3} color={'white'} size={'8px'} className={'min-w-[122px] gap-2'}/> : 
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 10.0558L6.33333 14.3891L15 2.11133" stroke={company.template.mainColor} stroke-width="2.88889" stroke-linecap="round"/>
              </svg>
            }
          </div>:
          <div
            onClick={event => {
              event.stopPropagation()
              setLoad(true)
              addProductServer(product.id, [], 1, {isChosenGift: true}).then(() => setLoad(false))
            }}
            className={`${maxChosenGifts && 'opacity-50 pointer-events-none'} flex items-center justify-center w-full h-[35px] border-[2px] border-main text-main rounded-[18px] cursor-pointer -tracking-[.01em] font-bold  duration-500`}
          >
            {load ? <Preloader countOfDot={3} color={'white'} size={'8px'} className={'min-w-[122px] gap-2'}/> : 'Выбрать'} 
          </div>
        }
      </>
    </div>
  )
}