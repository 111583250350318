import React, { useEffect, useState } from "react";
import { useMetricsContext } from "../../../contexts/MetricsContext";
import { useAppContext } from "../../../contexts/App";
import { Link, useLocation } from "react-router-dom";
import YandexMapRating from "../yandexMapRating";
import { NotificationBlock } from "../../../contexts/VisitorContext";
import Smartphone from "../../icons/Smartphone";
import { BaseButton } from "../button/BaseButton";
import YandexIcon from "../../icons/YandexIcon";
import YandexWhiteIcon from "../../icons/YandexWhiteIcon";
import StarRatingIcon from "../../icons/StarRatingIcon";
import logoFooter2 from "../../../images/logoFooter2.svg";
import logoFooter2Light from "../../../images/logoFooter2Light.svg";
import AppStore from "../../icons/AppStore";
import GooglePlay from "../../icons/GooglePlay";
import RuStore from "../../icons/RuStore";
import TelegramRoundIcon from "../../icons/TelegramRoundIcon";
import VkRoundIcon from "../../icons/VkRoundIcon";
import QRCode from "react-qr-code";


export default function Footer() {
  const { company, branch, city } = useAppContext()
  let location = useLocation();

  const [showInstallApp, setShowInstallApp] = useState(true);

  useEffect(() => {
    if (location.pathname?.split('/')?.pop() === "/cart" || location.pathname.includes("/order/")) {
      setShowInstallApp(false);
    }
  }, [location.pathname]);

  const theme = company?.template?.theme;

  const { metricsEvent } = useMetricsContext();

  const [yandexMapRating, setYandexMapRating] = useState<number>(5);

  return (
    <>
      <YandexMapRating setRating={setYandexMapRating} />
      <div className="bg-gray-10 shadow-[0px_-4px_80px_rgba(0,0,0,0.06)] dark:bg-gray-50">
        <NotificationBlock place="footer" />
        <footer className="container bg-gray-10 px-[15px] pb-8 pt-5 dark:bg-gray-50 md:flex-row lg:px-[75px]">
          {showInstallApp &&
            (!!company?.iosAppLink ||
              !!company?.androidAppLink ||
              !!company?.rustoreAppLink ||
              !!company?.footerQrCodeLink) && (
              <div className="mt-[30px] overflow-hidden rounded-[45px] bg-lightRed dark:bg-lightBlack lg:mt-[50px]">
                <div
                  className={`grid grid-cols-1 ${company?.footerScreenshot !== "" ? "xl:grid-cols-[510px_1fr]" : ""}`}
                >
                  {!!company?.footerScreenshot && (
                    <div className="first-column relative hidden overflow-hidden xl:block">
                      <div className="absolute left-[160px] top-[75px] z-0">
                        <img
                          className="rotate-[-8deg]"
                          src={company.footerScreenshot}
                          alt="Мобильная версия сайта"
                          width={300}
                          height={650}
                        />
                      </div>
                      <div className="absolute left-[53px] top-[2px] z-10">
                        <Smartphone />
                      </div>
                    </div>
                  )}
                  <div className="second-column px-4 py-5 md+:pb-[45px] md+:pl-[46px] md+:pt-[50px]">
                    <p
                      className={`mb-[5px] text-center text-xl font-normal md+:mb-[10px] ${company?.footerScreenshot !== "" ? "xl:text-left" : ""} lg:text-5xl lg:font-medium text-gray-60 dark:text-white`}
                    >
                      Скачивай удобное <br /> мобильное приложение
                    </p>
                    <p
                      className={`text-center text-base font-normal text-orange ${company?.footerScreenshot !== "" ? "xl:text-left" : ""} xl:text-[22px]`}
                    >
                      И получай призы к своему первому заказу
                    </p>
                    <div
                      className={`mt-5 flex justify-center gap-x-[122px] xl:mt-[35px] ${company?.footerScreenshot !== "" ? "xl:justify-start" : ""}`}
                    >
                      <div>
                        {!!company?.iosAppLink && (
                          <a
                            href={company.iosAppLink}
                            onClick={() => metricsEvent("app-icon")}
                            target="_blank"
                            className="mb-[15px] block md+:mb-[11px]"
                          >
                            <BaseButton className="w-full bg-white md+:w-auto md+:px-[99px] md+:py-[5px]">
                              <AppStore />
                            </BaseButton>
                          </a>
                        )}
                        {!!company?.androidAppLink && (
                          <a
                            href={company.androidAppLink}
                            onClick={() => metricsEvent("app-icon")}
                            target="_blank"
                            className="mb-[15px] block md+:mb-[11px]"
                          >
                            <BaseButton className="w-full bg-white md+:w-auto md+:px-[99px] md+:py-[5px]">
                              <GooglePlay />
                            </BaseButton>
                          </a>
                        )}
                        {!!company?.rustoreAppLink && (
                          <a
                            href={company.rustoreAppLink}
                            onClick={() => metricsEvent("app-icon")}
                            target="_blank"
                            className="block"
                          >
                            <BaseButton className="w-full bg-white md+:w-auto md+:px-[99px] md+:py-[5px]">
                              <RuStore />
                            </BaseButton>
                          </a>
                        )}
                      </div>
                      {!!company?.footerQrCodeLink && (
                        <div className="hidden md+:block">
                          <div className="bg-white p-4">
                            <QRCode
                              value={company.footerQrCodeLink}
                              size={109}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

          <div>
            <div className="grid">
              <div className="gap-x-[minmax(0, auto)] grid grid-cols-1 gap-y-5 py-5 lg:grid-cols-[260px_269px_164px_141px] lg:gap-x-[calc((100%_-_260px_-_269px_-_164px_-_141px_)/3)] lg:py-[21px]">
                <div className="bg-transparent text-center lg:text-left">
                  {branch?.contacts?.at(0)?.phone && (
                    <div>
                      <h2 className="mb-[6px] text-[22px] font-medium md+:text-3xl text-gray-60 dark:text-white">
                        Связаться с нами
                      </h2>
                      <a
                        href={`tel:${branch?.contacts?.at(0)?.phone}`}
                        className="no-underline"
                      >
                        <p className="text-sm font-medium text-main md+:text-xl md+:font-normal md+:text-gray-60 md+:dark:text-white">
                          {branch?.contacts?.at(0)?.phone}
                        </p>
                      </a>
                    </div>
                  )}
                </div>
                <div className="bg-transparent">
                  <div className="grid grid-cols-3 grid-rows-2 gap-x-[30px] gap-y-[10px] border-b border-t border-b-darkGray border-t-darkGray px-[29px] py-[20px] text-sm font-medium lg:grid-cols-[auto_auto] lg:grid-rows-3 lg:gap-x-[100px] lg:gap-y-[16px] lg:border-none lg:px-0 lg:py-0 lg:text-xl">
                    <div className="order-3 md+:order-1 text-gray-60 dark:text-white">
                      <Link to={`/${city.slug}/about`}>О нас</Link>
                    </div>
                    <div className="order-2 md+:order-2 text-gray-60 dark:text-white">
                      <Link to={`/${city.slug}/stock`}>Акции</Link>
                    </div>

                    <div className="order-6 md+:order-3 text-gray-60 dark:text-white">
                      <Link to={`/${city.slug}/payment`}>Доставка</Link>
                    </div>
                    <div className="order-4 md+:order-4 text-gray-60 dark:text-white">
                      <Link to={`/${city.slug}`}>Каталог</Link>
                    </div>

                    <div className="order-5 md+:order-5 text-gray-60 dark:text-white">
                      <Link to={`/${city.slug}/reviews`}>Отзывы</Link>
                    </div>
                    <div className="order-1 md+:order-6 text-gray-60 dark:text-white">
                      <Link to={`/${city.slug}/payment`}>Оплата</Link>
                    </div>
                  </div>
                </div>
                <div className="flex items-center justify-center bg-transparent lg:items-start">
                  {company?.organizationYandexId && (
                    <div className="h-[55px] w-[164px] rounded-[5px] bg-yandexRating p-[10px] dark:bg-gray-40">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center text-base font-medium">
                          <span>
                            {!(theme === "dark") && <YandexIcon />}
                            {theme === "dark" && <YandexWhiteIcon />}
                          </span>
                          <span className="ml-1 dark:text-white">
                            {yandexMapRating.toFixed(2)}
                          </span>
                        </div>
                        <div className="flex items-center">
                          {[...Array(5)].map((item) => (
                            <StarRatingIcon key={item} />
                          ))}
                        </div>
                      </div>
                      <div className="mt-[4px] text-[8px] font-normal dark:text-white">
                        <p>Рейтинг организации в Яндексе</p>
                      </div>
                    </div>
                  )}
                </div>

                {!!branch?.telegramLink || !!branch?.vkCommunities?.at(0) ? (
                  <div className="flex items-center justify-between gap-x-[35px] rounded-3xl bg-main pb-[14px] pl-[21px] pr-[14px] pt-[15px] dark:bg-main lg:items-start lg:justify-end lg:bg-transparent lg:px-0 lg:py-0 lg:dark:bg-transparent">
                    <div className="block flex-grow lg:hidden">
                      <h3 className="text-base font-medium">
                        Мы в социальных <br /> сетях
                      </h3>
                    </div>
                    <div className="flex items-center justify-between gap-x-[10px] lg:gap-x-[16px]">
                      {!!branch?.telegramLink && (
                        <a href={branch.telegramLink} target="_blank">
                          <TelegramRoundIcon />
                        </a>
                      )}
                      {!!branch?.vkCommunities?.at(0) && (
                        <a
                          href={`https://vk.com/club${branch?.vkCommunities?.at(0)?.groupId}`}
                          target="_blank"
                        >
                          <VkRoundIcon />
                        </a>
                      )}
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="mb-[31px] grid grid-cols-1 gap-y-5 lg:mb-[70px] lg:grid-cols-[243px_1fr_183px] lg:gap-y-0">
                <div className="order-2 bg-transparent lg:order-1">
                  <div className="flex flex-col items-center text-[13px] lg:items-start xl:text-base">
                    <a
                      href={`/${city.slug}/personalDataPolicy`}
                      target="_blank"
                      className="text-main underline"
                    >
                      Политика конфиденциальности
                    </a>
                    <a
                      href={`/${city.slug}/termsOfUse`}
                      target="_blank"
                      className="text-main underline"
                    >
                      Пользовательское соглашение
                    </a>
                  </div>
                </div>
                <div className="order-3 flex items-end justify-center bg-transparent lg:order-2 px-5">
                  <p className="text-[10px] font-normal text-footerBlack dark:text-footerWhite lg:text-base text-center">
                    Copyright &copy; {new Date().getFullYear()} «{company.title}». Все права защищены
                  </p>
                </div>

                <div className="order-1 bg-transparent text-center lg:order-3 lg:text-right">
                  <div className="text-base font-normal dark:text-gray-10">
                    Работает на технологии
                  </div>
                  {company.template.theme === "dark" && (
                    <Link
                      to={process.env.NEXT_PUBLIC_BASE_URL || "/"}
                      target="_blank"
                      className="flex justify-center lg:justify-end"
                    >
                      <img src={logoFooter2} className="" alt="" />
                    </Link>
                  )}
                  {company.template.theme === "light" && (
                    <Link
                      to={process.env.NEXT_PUBLIC_BASE_URL || "/"}
                      target="_blank"
                      className="flex justify-center lg:justify-end"
                    >
                      <img src={logoFooter2Light} className="" alt="" />
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
