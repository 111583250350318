import { useEffect, useState } from "react";
import { useAppContext } from "../../../../contexts/App";
import useForm from "../../../../hooks/useForm";
import { apiClient } from "../../../../libs/api/apiClient";
import CloseIcon from "../../../../components/icons/CloseIcon";
import InputSelector from "../../../../components/common/form/input/InputSelector";
import { InputSelectorAddressSearchWithSaved } from "../../../../components/common/form/input/InputSelectorDadata";
import { TextInput } from "../../../../components/common/form/input/TextInput";
import { BaseButton } from "../../../../components/common/button/BaseButton";
import { useAddressContext } from "../../../../contexts/AddressContext";
import CustomRadioButton from "../../../../components/common/form/radio/CustomRadioButton";
import CustomTooltip from "../../../../components/common/tooltip";
import { formatAddress } from "../../../../libs/helpers/formatAddress";
import PopupActionSheet from "../../../../components/common/popup/PopupActionSheet";
import { useAuthContext } from "../../../../contexts/AuthContext";
import { Preloader } from "../../../../components/common/preloader";
import { ProfileLayout } from "../../../../components/layouts/profile";


interface ICreateAddress {
  data: any,
  mode: string,
  allUserAddresses: any[],
  close: () => void,
  success: () => void,
  cities: any
}

export function CreateAddress({mode, data, allUserAddresses, close, success, cities}: ICreateAddress){
  const { city, company, address: selectedAddress } = useAppContext()
  const { trySaveDeliveryPoint } = useAddressContext()
  const { data: tempData, updateField: updateTempField } = useForm({...data, ...data.address});

  const [errorMessage, setErrorMessage] = useState<any>(null)
  const [newCity, setNewCity] = useState<any>(city)
  const [ deliveryAddress, setDeliveryAddress] = useState<any>({name: '', ...data.address})


  useEffect(() => {
    setDeliveryAddress({name: data.address?.title || '', ...data.address})
  }, [data.address?.title])

  const selectDeliveryAddress = async (addr: any) => {
    if (addr.deliveryAddressId) {
      const existingAddress = allUserAddresses.find(o => o.id === addr.deliveryAddressId)
      if (existingAddress) {
        setDeliveryAddress(existingAddress.address)

        updateTempField('floor', existingAddress.floor)
        updateTempField('flat', existingAddress.flat)
        updateTempField('entrance', existingAddress.entrance)
        return;
      }
    }

    let { data, isCoordinatesCanBeClarify } = await apiClient.suggestions.getAddressSuggestions(addr.name, [city.guid], city.lat, city.lon)

    if (!data || (data && data?.length == 0)) {
      console.log('не найден адрес')
      return
    }

    if (isCoordinatesCanBeClarify && data[0]?.isCoordinatesNeedToClarify) {
      let clarifyCoords = await apiClient.suggestions.getCoordsByAddress(formatAddress(data[0], [], true))
      console.log({clarifyCoords})
      if (clarifyCoords.data) {
        setDeliveryAddress({ ...data[0], lat: clarifyCoords.data.lat, lon: clarifyCoords.data.lon, name: formatAddress(data[0], []) })
      }
      return
    }

    data = data?.map(({ addressId, ...o }: any, i: number) => ({
      type: 'delivery',
      id: i,
      name: formatAddress(o, []),
      deliveryAddressId: addressId || null,
      pickupPointId: null,
      lat: parseFloat(o.lat),
      lon: parseFloat(o.lon),
      rawData: o
    }))

    if (data[0]) {
      console.log({setDeliveryPoint: JSON.stringify({ ...data[0].rawData, name: data[0].name })})
      setDeliveryAddress({ ...data[0].rawData, name: data[0].name })
    }
  }

  function editAddress(addr: any, addressId: any) {
    console.log('editAddress')
    if (addressId === selectedAddress?.point?.addressId) {
      trySaveDeliveryPoint(addr.address, {...tempData, id: addressId}).then((status: any) => {
        if (404 === status) {
          setErrorMessage('Указанный адрес не входит ни в одну зону доставки текущего филиала.')
        } else if ('notHouse' === status) {
          setErrorMessage('Пожалуйста, укажите номер дома')
        } else {
          success()
        }
      })
    } else {
      apiClient.profileAddress.editAddress(addr, addressId).then((res) => {
        const {data: newData, status, message} = res
        console.log(res)
        if (status === 422) {
          setErrorMessage(message)
          // setLoading(false)
          return
        } else if (status === 200 || status === 201) {
          success()
          return
        } else  {
          setErrorMessage('Что-то пошло не так...')
          // setLoading(false)
        }
      })
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (deliveryAddress) {
      const addr = { ...tempData, address: deliveryAddress, city: city.guid};
      if (mode === 'edit') {
        editAddress(addr, data.id)
      } else {
        apiClient.profileAddress.addAddress(addr).then(({data: newData, status, message}) => {
          if(status === 422) setErrorMessage(message)
          if (newData) {
            setErrorMessage(null)
            success()
          }
        })
      }
    }
  }
  return (
    <form onSubmit={e => handleSubmit(e)}  className={"w-full flex flex-col gap-y-5 pt-5"}>
      {
        mode === 'create' && 
        <div className={"flex justify-between"}>
          <p className={"text-lg font-medium text-dark dark:text-light"}>Новый адрес</p>
          <CloseIcon
            className={"w-[13px] h-[13px] cursor-pointer"}
            colorClassName={"fill-gray-30"}
            onClick={close}
          />
        </div>
      }
      <div className={"flex flex-col gap-y-4 sm:flex-row justify-between sm:gap-x-4"}>
        <InputSelector
          placeholder={"Выберите город"}
          variants={Object.values(cities).map(({slug, title}: any) => ({id: slug, name: title}))}
          value={newCity.slug}
          onChange={slug => {
            setNewCity(cities[slug])
            setDeliveryAddress({name: ''})
            updateTempField('deliveryAddress', null)
            setErrorMessage(null)
          }}
        />
        <InputSelectorAddressSearchWithSaved
          value={deliveryAddress}
          placeholder={"Добавить новый адрес"}
          onChange={(a: any) => {
            setErrorMessage(null)
            selectDeliveryAddress(a)
          }}
          guids={newCity ? [newCity.guid] : []}
          lat={newCity ? newCity.lat : null}
          lon={newCity ? newCity.lon : null}
          allUserAddresses={allUserAddresses.filter(o => o.id != null)}
          activeIcon={'iconSearch'}
        />
      </div>
      <div className={"flex flex-col gap-y-4 sm:flex-row sm:gap-x-4"}>
        <div className={"w-full flex flex-row gap-x-4"}>
          <TextInput
            placeholderInput={'Подъезд'}
            name={'entrance'}
            value={tempData.entrance || ''}
            onChange={(value)=>{updateTempField('entrance', value)}}
            className={"w-full "}
            classNameInput={""}
          />
          <TextInput
            placeholderInput={'Этаж'}
            name={'floor'}
            value={tempData.floor || ''}
            onChange={(value)=>{updateTempField('floor', value)}}
            className={"w-full"}
          />

          <TextInput
            required={true}
            placeholderInput={'Квартира'}
            name={'flat'}
            value={tempData.flat || ''}
            onChange={(value)=>{updateTempField('flat', value)}}
            className={"w-full "}
          />
        </div>
      </div>

        <div className={`${mode === 'edit' && (tempData.deliveryZone?.freeDeliveryFromTotal > 0 && tempData.deliveryZone?.freeDeliveryFromTotal !== null) ? 'opacity-1': 'hidden'} flex flex-row items-center gap-x-2`}>
          <div className={"block"}>
            <div className='cursor-default text-sm flex items-center justify-center h-5 w-5 border-[1.5px] border-main text-main font-medium rounded-full'>!</div>
          </div>
          <p className={"text-main"}>По выбранному адресу минимальная сумма до бесплатной доставки {tempData.deliveryZone?.freeDeliveryFromTotal} ₽</p>
        </div>
      {errorMessage && <p className={"text-main font-medium"}>{errorMessage}</p>}
      <div className={'flex flex-row gap-x-3'}>
        <BaseButton type={'submit'} className={"w-1/3 bg-main text-white font-medium hover:opacity-80"}>Сохранить</BaseButton>
        {mode === 'edit' && <BaseButton onClick={close} className={"w-1/3 bg-orderbtn text-main font-medium"}>Отмена</BaseButton>}
      </div>
    </form>
  )
}

interface IAddress {
  data: {
    entrance?: string,
    floor?: string,
    apartment?: string,
    flat?: string,
    address?: any,
    city: any,
    deliveryZone?: any,
    id?:any,
  },
  edit: boolean,
  remove: () => void,
  startEdit: () => void,
  closeEdit: () => void,
  successEdit: () => void,
  allUserAddresses: any[],
  cities: any,
}
export function Address({data, edit, allUserAddresses, remove, startEdit, closeEdit, successEdit, cities}: IAddress) {
  const { address, entrance, floor, flat, city} = data
  const { address: selectedAddress, setAddress: setSelectedAddress, branch, company} = useAppContext()
  const {trySaveDeliveryPoint} = useAddressContext()
  const [isAcceptDeleteAddress, setIsAcceptDeleteAddress] = useState(false)

  const selectAddress = async (point: any) => {
    const deliveryPoint = {...point.address, addressId: point.id, selectAddress: true, deliveryZoneId: point.deliveryZone?.id};
    await trySaveDeliveryPoint(deliveryPoint, {entrance, floor, flat})
  }

  const editComponent = <CreateAddress
    data={data}
    mode={'edit'}
    allUserAddresses={allUserAddresses}
    close={closeEdit}
    success={successEdit}
    cities={cities}
  />

  return (
    <>
      <div className={"flex flex-row gap-x-4 items-center"}>
        <CustomRadioButton
          checked={data.id === selectedAddress?.point?.addressId}
          onClick={async ()=>{
            if (data.id !== selectedAddress?.point?.addressId) await selectAddress(data)
          }}/>

        <div className={"flex flex-col gap-x-3 gap-y-2"}>
          <p className={`${edit ? 'text-gray-30' : ' text-dark dark:text-light'}`}>{formatAddress(address, [])} {entrance? `под ${entrance},` : ``} {floor? `эт ${floor},` : ``} кв {flat}</p>
          <div className={'flex flex-row gap-x-3'}>
            {
              !edit && 
              <>
                <p onClick={startEdit} className={"text-main text-sm cursor-pointer hover:opacity-80"}>Изменить</p>
                <p onClick={() => setIsAcceptDeleteAddress(true)} className={"text-main text-sm cursor-pointer hover:opacity-80"}>Удалить</p>
              </>
            }
          </div>
          { edit && editComponent }
        </div>
        {
         !edit && (data.deliveryZone?.freeDeliveryFromTotal > 0 && data.deliveryZone?.freeDeliveryFromTotal !== null) &&
          <div className={"relative"}>
            <CustomTooltip
              symbol={'!'}
              color={"text-main"}
              positionClassName={'z-50 top-[20px] sm:top-[20px] right-4 md:top-[38px]  md:right-[-25px] lg:right-[-50px] w-[230px]'}
            >
              минимальная сумма до бесплатной доставки {data.deliveryZone?.freeDeliveryFromTotal} ₽
            </CustomTooltip>
          </div>

        }
      </div>
      <PopupActionSheet
        isActive={isAcceptDeleteAddress}
        closeIcon
        close={() => setIsAcceptDeleteAddress(false)}
      >
        <p className="text-center text-dark dark:text-light">Вы действительно хотите удалить адрес?</p>
        <BaseButton className="w-full bg-orderbtn text-main hover:bg-main hover:text-white mt-5" onClick={() => setIsAcceptDeleteAddress(false)}>Отмена</BaseButton>
        <BaseButton 
          className="w-full bg-main text-white hover:opacity-80 xs:mt-4 mt-3" 
          onClick={() => {
            remove()
            setIsAcceptDeleteAddress(false)
          }}
        >
          Удалить
        </BaseButton>
      </PopupActionSheet>
    </>
  )
}
export default function MyAddress() {
  const { branch, company } = useAppContext()
  const { user } = useAuthContext()
  const { fetchAllUserAddresses, allUserAddresses } = useAddressContext()


  const [editMode, setEditMode] = useState('none')
  const [editIndex, setEditIndex] = useState(-1)

  const [isLoading, setLoading] = useState<boolean>(true)
  const [openCreateForm, setOpenCreateForm] = useState(false)

  const fetchAddresses = () => {
    setLoading(true)
    fetchAllUserAddresses().then(() => {
      setEditIndex(-1)
      setLoading(false)
    })
  }

  const [cities, setCities] = useState<any>({})

  const fetchCities = () => {
    return apiClient.delivery.getCities(company.id).then(({data}) => {
      setCities(data.cities)
    })
  }

  useEffect(() => {
    if (user == null || branch?.id == null) {
      return;
    }
    setLoading(true)

    Promise.all([
      fetchAllUserAddresses(),
      fetchCities()
    ]).then(() => {
      setLoading(false)
    })

    fetchAddresses()
  }, [])
  return (
    <ProfileLayout>
      <div className={"flex w-full gap-y-10 z-[45] static"}>
        <div className={"flex w-full flex-col gap-y-6"}>
          <p className={"text-2xl font-medium text-dark dark:text-light"}>Мои адреса</p>

          {isLoading && <div className={'w-full py-[20vh]'}><Preloader countOfDot={4} size="10px"/></div>}

          {
            !isLoading && allUserAddresses.map((item:any, index:any) => {
              const remove = () => {
                apiClient.profileAddress.removeAddress(item.id).then(() => {
                  fetchAddresses()
                })
              }  

              return <Address
                key={item.id}
                data={item}
                edit={editIndex === index}
                startEdit={() => {setEditIndex(index)}}
                closeEdit={() => setEditIndex(-1)}
                successEdit={() => {fetchAddresses()}}
                allUserAddresses={allUserAddresses}
                cities={cities}
                remove={remove}
              />
            })
          }

          {
            openCreateForm &&
            <CreateAddress
              data={{}}
              mode={'create'}
              allUserAddresses={allUserAddresses}
              cities={cities}
              close={() => setOpenCreateForm(false)}
              success={() => {
                setOpenCreateForm(false)
                fetchAddresses()
              }}
            />
          }

          {!openCreateForm &&
            <BaseButton
              onClick={() => {
                setOpenCreateForm(true)
              }}
              className={"w-2/3 sm:w-1/2 border-[2px] border-main hover:bg-main font-medium text-main hover:text-white"} >
              Добавить новый адрес
            </BaseButton>
          }
        </div>
      </div>
    </ProfileLayout>
  )
}