import React, {createContext, useContext, useState, useEffect, useRef} from "react";
import { useAppContext } from "./App";
import { useAuthContext } from "./AuthContext";
import { uid } from "uid";
import moment from "moment";
import { CartProductType, CartType, OptionProductType } from "../types/cart";
import { localStorageWrap } from "../libs/helpers/localStorageWrap";
import { apiClient } from "../libs/api/apiClient";
import useDebounce from "../hooks/useDebounce";
import PopupActionSheet from "../components/common/popup/PopupActionSheet";
import { BaseButton } from "../components/common/button/BaseButton";
import Popup from "../components/common/popup";
import { useMetricsContext } from "./MetricsContext";
import { toast } from 'react-toastify';

type NoAddressWarning = null | 'toast' | 'popup'

export const clearCartObj = {
  cartId: '',
  products: [],
  raw: [],
  amount: 0,
  total: 0,
  count: 0,
  actions: [],
  branchId: 0,
  paymentType: "cash",
  discounts: {
    availableBonus: 0,
    promoCodeDiscount: 0,
    bonusDiscount: 0,
    bonusScoreWillBeAdded: 0,
    maxAppliedBonusDiscount: 0,
    totalDiscount: 0,
    discountWithoutBonus: 0,
    comments: [],
  },
  cartPromoCode: {
    activated: false,
    reason: '',
    promoCode: null
  },
  options: {
    paymentTypes: [],
    paymentTypeObjects: [],
    recommendable: [],
    giftsForChoose: [],
    maxChosenGifts: 0,
    isGiftForChooseDisabled: false,
    isDiscountsDisabled: false,
    isBonusDiscountsDisabled: false,
    isFreeDeliveryDisabled: false,
    isMakingOrderDisabled: false,
    isPromoCodesDisabled: false,
    disabledMakingOrderComment: "",
    disabledPromoCodesComment: ""
  },
  additionalFields: []
}

interface ContextType {
  cart: CartType; // Вся корзина 
  isInitCart: boolean;
  updateCart: (data: any) => Promise<any>; // Обновление корзины
  updateCartDebounce: (data: any) => void;
  updateDataCart: (data: any) => void;
  getProductCart: (productId: number, options?: OptionProductType[]) => CartProductType|null; // Получение товара из корзины по id товара из каталога и его опциям
  addProduct: (productId: number, options?: OptionProductType[], minCount?: number, additionalAttributes?: any, addressCheck?: boolean) => any; // Добавить товар в корзину (клиент) 
  updateProduct: (productUid: string, data: any) => void, // Обновить товар (клиент)
  synchronize: () => Promise<any>, // Синхронизировать товары в корзине с фронда и бека
  addProductServer: (productId: number, options?: OptionProductType[], minCount?: number, additionalAttributes?: any) => Promise<any>, // Добавить товар в корзину (сервер) 
  updateProductServer: (productUid: string, data: any) => Promise<any>, // Обновить товар (сервер)
  loading: boolean; // Загрузка данных
  addressPreloader: boolean; // фикс добавления в корзину, добавление прелоадера
  noAddressWarning: NoAddressWarning, //показывать диалог если нет адреса доставки
  setNoAddressWarning: (value: NoAddressWarning) => void;
  textInAddressAlert: string; //текс в диалоге, если нет адреса доставки
  setTextInAddressAlert: (v: string) => void;
  repeatOrder: (idOrder: number) => Promise<any>; //повторить заказ
  headerTextInAddressAlert: string;
  setHeaderTextInAddressAlert: (v: string) => void
  setAddressPreloader: (v: boolean) => void;
  clearCart: () => Promise<any>;
}

const CartContext = createContext<ContextType>({
  cart: clearCartObj,
  isInitCart: false,
  updateCart: async () => Promise.resolve({}),
  updateCartDebounce: () => {},
  updateDataCart: () => {},
  getProductCart: () => null,
  addProduct: () => {},
  updateProduct: () => {},
  synchronize: () => Promise.resolve(null),
  addProductServer: () => Promise.resolve(null),
  updateProductServer: () => Promise.resolve(null),
  loading: false,
  addressPreloader: false,
  noAddressWarning: null,
  setNoAddressWarning: () => {},
  textInAddressAlert: 'Покажем меню именно того филиала, который будет готовить ваш заказ!',
  setTextInAddressAlert: () => {},
  repeatOrder: () => Promise.resolve(false),
  headerTextInAddressAlert: 'Какой у вас адрес?',
  setHeaderTextInAddressAlert: () => {},
  setAddressPreloader: () => {},
  clearCart: () => Promise.resolve({}),
})

export function CartContextWrapper({children, cart, setCart}: any) {
  const { company, branch, address, openTimeInfo, allProducts } = useAppContext()
  const { user } = useAuthContext()
  const { metricsEvent } = useMetricsContext()

  const [isInitCart, setIsInitCart] = useState(false)
  const [isSynchronizedCart, setIsSynchronizedCart] = useState(true)

  const [loading, setLoading] = useState(false)
  const [addressPreloader, setAddressPreloader] = useState(false)
  const [noAddressWarning, setNoAddressWarning] = useState<NoAddressWarning>(null)
  const [textInAddressAlert, setTextInAddressAlert] = useState<string>('')
  const [headerTextInAddressAlert, setHeaderTextInAddressAlert] = useState('')
  const [isChooseCart, setIsChooseCart] = useState(false)
  const [isOpenErrorPopup, setOpenErrorPopup] = useState<boolean>(false)

  //needed for hydration
  useEffect(() => {
    setIsSynchronizedCart(JSON.parse(localStorageWrap.getItem('isSynchronizedCart') || 'true'))
  }, [])

  // Если корзина привязанная к пользователю отличается от корзины локально проверяем, и предлагаем замену
  useEffect(() => {
    if (user && user?.cartId && branch && user?.cartId !== cart.cartId) {
      // Если у пользователя есть корзина, а в локальной ничего нет, то заменяем её
      if (!cart.raw?.length) {
        setLoading(true)
        apiClient.cart.get(branch.id, user.cartId).then((data) => {
          if (data?.products) {
            updateDataCart(data)
          }
          setLoading(false)
        })
      } else {
      // Иначе проверяем есть ли товары в корзине что записана на юзера и если есть предлагаем пользователю выбрать из той что сохранена на аккаунте и той что локально 
        setLoading(true)
        apiClient.cart.get(branch.id, user.cartId).then((data) => {
          if(data.raw){
            setIsChooseCart(true)
            setLoading(false)
          }
        })
      }
    }
  }, [user?.cartId, branch?.id])

  function setSynchronizedCart(value: boolean, products: any = null) {
    setIsSynchronizedCart(value)
    localStorageWrap.setItem('isSynchronizedCart', JSON.stringify(value))

    if (value) {
      localStorageWrap.removeItem('localProducts')
    } else {
      localStorageWrap.setItem('localProducts', JSON.stringify(products || cart.raw))
    }
  }

  // Принемает объект корзины и заменяет имеющийся
  function updateDataCart(data: CartType) {
    localStorageWrap.setItem('cartId', data?.cartId || '')
    setCart(data)
  }

  const [updateCartObject, setUpdateCartObject] = useState<any>({})

  useEffect(() => {
    if (Object.keys(updateCartObject).length) {
      setLoading(true)
    }
  }, [updateCartObject])

  const debouncedUpdateCartObject = useDebounce(updateCartObject, 700);

  useEffect(() => {
    if (Object.keys(updateCartObject).length) {
      let sendData = JSON.parse(JSON.stringify(updateCartObject))
      setUpdateCartObject({})
      updateCart(sendData)
    }
  }, [debouncedUpdateCartObject])
  
  // Принемает объект изменяемых полей корзины
  async function updateCartDebounce(data: any) {
    setUpdateCartObject({...updateCartObject, ...data})
  }

  const requestCounter = useRef(0);

  async function updateCart(data: any) {
    setLoading(true)
    const currentRequest = ++requestCounter.current;
    console.log({updateCart: data})
    const response = await apiClient.cart.update(branch.id, {cartId: cart.cartId, ...data})
    console.log({response})

    if (currentRequest === requestCounter.current && (response.status === 200 || response.status === 201)) {
      updateDataCart(response)
      setLoading(false)
    }

    return response
  }

  useEffect(() => {
    if (branch.id && cart.cartId) {
      synchronize()
    }
  }, [branch.id])

  async function synchronize() {  
    console.log('Синхронизация корзины', {raw: cart.raw})
    let response = await updateCart({raw: cart.raw})
    
    if (response.status === 200 || response.status === 201) {
      setSynchronizedCart(true)
    } else {
      toast.error(`Ошибка синхронизации корзины `, { 
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: company.template.theme,
      });
    }

    return response
  }
  
  function addProduct(productId: number, options: OptionProductType[] = [], minCount: number = 0, additionalAttributes: any = {}, addressCheck=true) {
    if(address?.point == null && addressCheck) {
      setTextInAddressAlert('Чтобы добавить блюдо в корзину нужно указать адрес. Хотим убедиться, что вы в зоне доставки.')
      setNoAddressWarning('toast')
      setHeaderTextInAddressAlert('Какой у вас адрес?')
      return
    }

    metricsEvent('add-product')

    let count = minCount ? minCount : 1

    let data: any = {
      uid: uid(32),
      count,
      options,
      productId,
      amount: (allProducts[productId].price + options.reduce((sum: number, option: OptionProductType) => sum + option.price * option.count, 0)) * count,
      itemAmount: allProducts[productId].price,
      itemTotal: allProducts[productId].price,
      total: (allProducts[productId].price + options.reduce((sum: number, option: OptionProductType) => sum + option.price * option.count, 0)) * count,

      bonusDiscount: 0,
      promoCodeDiscount: 0,
      comment: "",
      errors: [],
      isAdded: false,
      isAddedByGoodAddableAttribute: false,
      isChosenGift: false,
      isFree: false,
      isGift: false,
      isCountChangeable: true,
      isDeletable: true,
    }

    setCart({
      ...cart, 
      raw: [...cart.raw, data], 
    })

    setSynchronizedCart(false, [...cart.raw, data])

    return data
  }

  async function addProductServer(productId: number, options: OptionProductType[] = [], minCount: number = 0, additionalAttributes: any = {}) {
    async function f() {
      let data: any = {
        product: {
          productId,
          count: minCount ? minCount : 1,
          options
        },
        ...additionalAttributes
      }
  
      if (cart?.cartId) {
        data['cartId'] = cart.cartId
      }
  
      setLoading(true)
      return apiClient.cart.add(branch.id, data).then((data) => {
        if (data?.products) {
          updateDataCart(data)
        }
        setLoading(false)
      })
    }

    metricsEvent('add-product')
    
    if (!isSynchronizedCart) {
      return synchronize().then(f)
    } else {
      return f()
    }
  }

  function updateProduct(productUid: string, data: any) {
    let products = JSON.parse(JSON.stringify(cart.raw))

    if (data?.count <= 0) {
      products = products.filter((p: CartProductType) => p.uid !== productUid)
    } else {
      for (let index = 0; index < products.length; index++) {
        if (products[index].uid === productUid) {
          products[index] = {...products[index], ...data}
      
          products[index] = {
            ...products[index],
            amount: (products[index].itemAmount + products[index].options.reduce((sum: number, option: OptionProductType) => sum + option.price * option.count, 0)) * products[index].count,
            total: (products[index].itemAmount + products[index].options.reduce((sum: number, option: OptionProductType) => sum + option.price * option.count, 0)) * products[index].count,
          }

          break
        }
      }
    }
    
    setCart({
      ...cart, 
      raw: products, 
    })

    setSynchronizedCart(false, products)
  }

  async function updateProductServer(productUid: string, data: any) {
    async function f() {
      if (cart?.cartId) {
        data['cartId'] = cart.cartId
      }
      
      setLoading(true)
      return apiClient.cart.updateProduct(branch.id, productUid, data).then((data) => {
        if (data?.products) {
          updateDataCart(data)
        }
        setLoading(false)
      })
    }

    if (!isSynchronizedCart) {
      return synchronize().then(f)
    } else {
      return f()
    }
  }

  // Получаем объект товара из корзины 
  function getProductCart(productId: number, options: OptionProductType[] = []) {
    function getProductHash(productId: number, options: OptionProductType[] = []): string {
      options.sort((a: OptionProductType, b: OptionProductType) => a.id - b.id)
      return `${productId}_${options.map(option => `${option.groupId}_${option.id}_${option.count}`).join('__')}`
    }

    let hash = getProductHash(productId, options)

    let products = cart.raw?.length ? cart.raw?.filter((p: CartProductType) => !p.isChosenGift && p.isCountChangeable) : []
    
    for (let index = 0; index < products?.length; index++) {
      if (getProductHash(products[index].productId, products[index].options) === hash) {
        return products[index]
      }
    }

    return null
  }

  // Повторение заказа
  function repeatOrder(idOrder: number) {
    return apiClient.order.getCart(idOrder).then((data) => {
      if (data.cartId) {
        updateDataCart(data)
        return true;
      } else {
        setOpenErrorPopup(true)
        return false;
      }
    })
  }

  function handleChooseCart (isSavePrevCart: boolean) {
    const localCartId = localStorageWrap.getItem('cartId')
    apiClient.cart.get(branch.id, isSavePrevCart? user.cartId : localCartId).then((data) => {
      if (data?.products) {
        updateDataCart(data)
      }
      setIsChooseCart(false)
    })
  }

  async function clearCart() {
    return apiClient.cart.clear(branch.id, cart.cartId).then((data) => {
      updateDataCart(data)
    })
  }

  return (
    <CartContext.Provider 
      value={{ 
        cart, 
        isInitCart,
        updateCart,
        updateCartDebounce,
        updateDataCart,
        addProduct, 
        updateProduct,
        synchronize,
        addProductServer,
        updateProductServer,
        getProductCart,
        loading,
        noAddressWarning,
        setNoAddressWarning,
        textInAddressAlert,
        setTextInAddressAlert,
        repeatOrder,
        headerTextInAddressAlert,
        setHeaderTextInAddressAlert,
        addressPreloader,
        setAddressPreloader,
        clearCart,
      }}
    >
      <PopupActionSheet isActive={isChooseCart} close={() => {}}>
        <div className="flex flex-col justify-center gap-y-3">
          <p className="font-bold">Выберите корзину</p>
          <BaseButton
            className="bg-main text-white w-full"
            onClick={() => handleChooseCart(true)}
          >
            Выбрать предыдущую корзину
          </BaseButton>
          <BaseButton 
            className="bg-main text-white w-full"
            onClick={() => handleChooseCart(false)}
          >
            Оставить текущую корзину
          </BaseButton>
        </div>
      </PopupActionSheet>
      <ErrorPopup isOpen={isOpenErrorPopup} close={() => setOpenErrorPopup(false)}/>
     
      {children}
    </CartContext.Provider>
  )
}

export function useCartContext() {
  return useContext(CartContext)
}


export function ErrorPopup ({isOpen, close}: any){
  return <Popup
    width={"400px"}
    isActive={isOpen}
    close={close}
    closeIcon={true}
  >
    <p className="text-2xl font-bold text-center dark:text-white">Ой-ой!</p>
    <p className="py-4 dark:text-white">Что-то пошло не так, повторите попытку позже</p>
    <BaseButton onClick={close} className={'w-full bg-main text-white hover:opacity-80'}>Хорошо</BaseButton>
  </Popup>

}
