import { useEffect, useRef, useState } from "react"
import { useAppContext } from "../../contexts/App"
import { NotificationBlock, sendEvent, useVisitorContext } from "../../contexts/VisitorContext"
import { apiClient, getCurrentDomain } from "../../libs/api/apiClient"
import Nav from "../../components/common/Nav"
import MainPageSlider from "../../components/pages/mainPage/Slider"
import Stories from "../../components/pages/mainPage/Stories"
import Catalog from "../../components/pages/mainPage/Catalog"
import { animateScroll } from "react-scroll"
import PrimaryLayout from "../../components/layouts/primary"
import Header from "../../components/common/header/header"
import { IStory } from "../../types/story"
import useSearch from "../../hooks/useSearch"
import getStories from "../../libs/ssrStartRequst/getStories"

export type CatalogCategory = {
  id: number,
  title: string,
  description: string,
  platforms: Array<string>,
  metaTitle: null|string,
  metaKeywords: null|string,
  metaDescription: null|string,
  goods: Array<number>
}

export type ActiveCategoryRef = {
  id: null | string,
  index: null | number,
  sentCategories: Array<any>,
  sendTimeoutHandle: any,
  setCategoryId: (id: string) => void
}

export type ProductTag = {
  id: number,
  code: string,
  text: string,
  color: string,
  background: string,
  icon: string
}

export default function Main() {
  const { branch } = useAppContext()
  const { visitorId } = useVisitorContext()

  const [catalog, setCatalog] = useState<Array<CatalogCategory>>([]);
  const [tags, setTags] = useState<Array<ProductTag>>([]);
  const [initialCatalog, setInitialCatalog] = useState(null);

  const {searchQuery, setSearchQuery, activeSearch, setActiveSearch, searchTags, setSearchTags, noSearchResult} = useSearch(setCatalog)

  const [stories, setStories] = useState<IStory[]>([]);

  const [searchInHeader, setSearchInHeader] = useState(false)
  const searchCategoriesWrapper: any = useRef(null)
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(-1);

  const headerRef = useRef<any>(null)
  let categoriesOffsetsRef = useRef<any>([]);

  useEffect(() => {
    categoriesOffsetsRef.current = []
  }, [catalog])

  useEffect(() => {
    getStories(getCurrentDomain(), branch.id)
      .then((stories) => {
        setStories(stories);
      })
      .catch((error) => {
        console.log(`Ошибка получаения историй: ${error}`);
      });

    apiClient.catalog.get(branch.id)
      .then(({ data }) => {
        const { catalog, tags } = data;

        setCatalog(catalog)
        setTags(tags);
        setInitialCatalog(catalog)
      })
      .catch(error => {
        console.log(`Ошибка получения каталога: ${error}`);
      })
  }, [branch.id]);

  // Активная сейчас категория
  const activeCategoryRef = useRef<ActiveCategoryRef>({
    id: null as null | string,
    index: null as null | number,
    sentCategories: [] as any[],
    sendTimeoutHandle: null as any,
    setCategoryId: (() => {}) as (id: string) => void
  }).current

  activeCategoryRef.setCategoryId =  (id: string | null) => {
    if (activeCategoryRef.id !== id) {
      activeCategoryRef.id = id

      // Отправка уведомления о посещении категории
      if (activeCategoryRef.sendTimeoutHandle) {
        clearTimeout(activeCategoryRef.sendTimeoutHandle)
      }
      activeCategoryRef.sendTimeoutHandle = setTimeout(() => {
        if (activeCategoryRef.id && visitorId) {
          const currentCategoryId = activeCategoryRef.id.split(`_`)[1]
          if (!activeCategoryRef.sentCategories.includes(currentCategoryId)) {
            const c = catalog?.filter((category: any) => category.id == currentCategoryId)[0];
            if (c) {
              sendEvent('section-visited', {target: `Раздел ${c.title}`, categoryId: currentCategoryId}).then()
              activeCategoryRef.sentCategories.push(currentCategoryId)
            }
          }
        }
      }, 1000)
    }
  }

  useEffect(() => {
    function handleScroll(e: any) {
      // Показ прилепающего меню в хедере
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (searchCategoriesWrapper && headerRef.current) {
        const searchHeight = searchCategoriesWrapper.current?.offsetHeight;
        const headerHeight = headerRef.current.offsetHeight - searchHeight;

        if (searchInHeader){
          if(scrollTop + headerHeight - 10 < searchCategoriesWrapper.current?.offsetTop){
            setSearchInHeader(false)
          }
        } else {
          if(scrollTop + searchHeight + headerHeight + 10 > searchCategoriesWrapper.current?.offsetTop){
            setSearchInHeader(true)
          }
        }
      }

      if (categoriesOffsetsRef.current.length === 0) {
        categoriesOffsetsRef.current = Array.from(document.querySelectorAll('.catalog-category')).map((category: any, index) => ({id: category.id, index: index, offset: category.offsetTop}))
      }

      let categoriesOffsets = categoriesOffsetsRef.current
      for (let index = categoriesOffsets.length - 1; index >= 0; index--) {
        if (categoriesOffsets[index].offset - 200 < scrollTop) {
          activeCategoryRef.setCategoryId(categoriesOffsets[index].id)
          setActiveCategoryIndex(categoriesOffsets[index].index)
          break
        }
      }
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [searchCategoriesWrapper, headerRef, searchInHeader]);

  useEffect(() => {
    animateScroll.scrollToTop()

    if (visitorId) {
      sendEvent('section-visited', {target: 'Главная'})
    }
  }, [])

  return (
    <PrimaryLayout
      header={<Header
        catalog={ catalog }
        setCatalog={ setCatalog }
        searchData={ {
          searchQuery,
          setSearchQuery,
          activeSearch,
          setActiveSearch,
        } }
        initialCatalog={ initialCatalog }
        searchInHeader={ searchInHeader }
        setSearchInHeader={ setSearchInHeader }
        headerRef={ headerRef }
        activeCategoryRef={ activeCategoryRef }
        activeCategoryIndex={ activeCategoryIndex }
      />}
    >
      <div className={'container'}>
        <Nav/>
        <MainPageSlider/>
        <Stories stories={ stories }/>
      </div>

      <div className={''}>
        <NotificationBlock place='main-top'/>
      </div>

      <div className={'container'}>
        <Catalog
          catalog={ catalog }
          tags={ tags }
          setCatalog={ setCatalog }
          searchTags={ searchTags }
          setSearchTags={ setSearchTags }
          noSearchResult={ noSearchResult }
          searchData={ {
            searchQuery,
            setSearchQuery,
            activeSearch,
            setActiveSearch,
          } }
          initialCatalog={ initialCatalog }
          searchCategoriesWrapper={ searchCategoriesWrapper }
          activeCategoryRef={ activeCategoryRef}
          activeCategoryIndex={activeCategoryIndex}
        />
      </div>
    </PrimaryLayout>
  )
}