import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { CartContextWrapper, useCartContext, clearCartObj } from './CartContext';
import { useAppContext } from './App';
import { isEqual } from 'lodash';
import { localStorageWrap } from '../libs/helpers/localStorageWrap';
import { CartType } from '../types/cart';
import { apiClient } from '../libs/api/apiClient';
import { AuthContextWrapper } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import PopupActionSheet from '../components/common/popup/PopupActionSheet';
import CloseIcon from '../components/icons/CloseIcon';
import { getPreorderAt } from '../libs/helpers/getPreorderAt';

interface ContextType {
  visitorId: any;
  topNotifications: any[];
  closedTopNotifications: any[];
  closeNotification: (uid: string) => void;
  actionsController: (actions: any) => void;
}

const VisitorContext = createContext<ContextType>({
  visitorId: null,
  topNotifications: [],
  closedTopNotifications: [],
  closeNotification: () => {},
  actionsController: () => {},
})

export async function sendEvent(type: string, data: any) {
  return apiClient.visitor.event({
    type,
    ...data,
  })
}


export default function VisitorContextWrapper({ children }: { children: React.ReactNode }) {
  const { company, branch, openTimeInfo, setOpenTimeInfo, address, city } = useAppContext()
  // Данные визита
  const [visitorId, setVisitorId] = useState<any>(null)
  const [topNotifications, setTopNotifications] = useState<any>({'open': [], 'cart': [], 'order': [], 'request': [], 'authorization': []})
  const [closedTopNotifications, setClosedTopNotifications] = useState<any>([])
  const [popupsData, setPopusData] = useState<any[]>([])
  const [closedPopups, setClosedPopups] = useState<any>([])

  function closeNotification(uid: string) {
    setClosedTopNotifications((prev: any) => [...prev, uid])
  }

  // Данные авторизации
  const [user, setUser] = useState<any>(null)

  // Данные корзины
  const [cart, setCart] = useState<CartType>(clearCartObj)

  // Отправка события 
  const [isSendedOpen, setIsSendedOpen] = useState<boolean>(false)

  async function getOpenTime() {
    if (openTimeInfo)
      return openTimeInfo

    let data = await apiClient.branch.getTime(branch?.id)
    
    setOpenTimeInfo(data)
    return data
  }

  async function sendOpenEvent() {
    if (!company || isSendedOpen) return

    const openInfo: any = await getOpenTime()

    if (!branch?.id)
      return

    // Получаем id корзины если есть локально 
    let data: any = {}
    const cartId = localStorageWrap.getItem('cartId')
    if (cartId) {
      data = { cartId }
    }

    // Добавляем обязательные поля для корзины 
    let cartForOpen: any = {
      deliveryType: address?.type === 'delivery' ? 'courier' : 'pickup',
      paymentType: branch.order.paymentTypes[0].value,
      deliveryCity: city.guid
    }

    if (address?.point?.deliveryZoneId) {
      cartForOpen['deliveryZoneId'] = address['delivery']?.deliveryZoneId
    }

    if (address?.type === 'delivery') {
      cartForOpen.deliveryAddress = {...address?.point, city: city.title};
    }

    if (address?.type === 'pickup') {
      cartForOpen.pickupPointId = address?.point?.id
    }

    let clientPhone = localStorageWrap.getItem('clientPhone')

    if (user?.phone || clientPhone) {
      cartForOpen['clientPhone'] = user?.phone || clientPhone
    }

    if (!openInfo.isOpen) {
      cartForOpen['preorder'] = !openInfo.isOpen
      cartForOpen = {...cartForOpen, ...getPreorderAt(branch.order.preorderAllowedTimes)}
    }

    // Если локально осталась не синхронизированная корзина, то отправляем её вместе с open
    const isSynchronizedCart = localStorageWrap.getItem('isSynchronizedCart')

    if (!JSON.parse(isSynchronizedCart || 'false')) {
      const localProducts = (products: any) => JSON.parse(localStorageWrap.getItem('localProducts') || '[]')
      cartForOpen = {...cartForOpen, raw: localProducts}
    }

    data.cart = cartForOpen

    // Отправляем событие open с ответа сохраняем visitorId и задаём cartId
    try {
      const res = await sendEvent('open', data)
      if (res.status !== 200) throw `event open status - ${res.status}`

      const { data: {visitorId, cartId, actions, cart}, status } = res
      console.log({'open': {visitorId, cartId, actions, cart}})
      setVisitorId(visitorId)

      // Сохраняем visitorId в sessionStorage не в state чтобы не сбрасывалось с новой вкладки или после перезагрузки и не localStorage чтобы сбрасывалось после закрытия браузера
      localStorageWrap.setItem('visitorId', visitorId)
      localStorageWrap.setItem('cartId', cartId)
      setIsSendedOpen(true)
      
      setCart(cart)
      localStorageWrap.setItem('isSynchronizedCart', JSON.stringify(true))

      actionsController(actions)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    sendOpenEvent()
    actionsController({authorization: JSON.parse(localStorageWrap.getItem('actionsFromAuth'))})
  }, [company, branch?.id])

  useEffect(() => {
    let timer = setInterval(() => {
      apiClient.visitor.getActions().then(({data}) => {
        actionsController({request: data})
      })
    }, 30000)

    return () => clearInterval(timer)
  }, [])

  // В корзине могут прилететь набор действий по воронкам
  useEffect(() => {
    cart?.actions && actionsController({cart: cart.actions})
  }, [cart?.actions])

  const popupsDataRef = useRef<any>(popupsData);
  const topNotificationsRef = useRef<any>(topNotifications);
    
  function actionsController(actions: any) {

    let allActions: any = Object.values(actions).reduce((acc: any, cur: any) => [...acc, ...cur], [])

    let temp = Object.values([...popupsDataRef.current, ...allActions.filter((action: {action: string, data: any}) => action.action === SHOW_POPUP).map((action: {action: string, data: any}) => action.data)].reduce((acc: any, cur: any) => ({...acc, [cur.uid]: cur}), {}))
    
    setPopusData(temp) 
    popupsDataRef.current = temp;

    ['open', 'cart', 'order', 'authorization'].forEach((source: string) => {
      if (Object.keys(actions).includes(source)) {
        let temp = actions[source]?.filter((action: {action: string, data: any}) => action.action === SHOW_TOP_NOTIFICATION)?.map((action: {action: string, data: any}) => action.data)
        setTopNotifications({...topNotificationsRef.current, [source]: temp}) 
        topNotificationsRef.current[source] = temp
      }
    })

    if (Object.keys(actions).includes('request')) {
      let allActionsUid = [...topNotificationsRef.current.open, ...topNotificationsRef.current.cart, ...topNotificationsRef.current.order, ...topNotificationsRef.current.request].map((data: any) => data.uid)
      let temp = actions?.request?.filter((action: {action: string, data: any}) => action.action === SHOW_TOP_NOTIFICATION)?.map((action: {action: string, data: any}) => action.data)?.filter((data: any) => !allActionsUid.includes(data.uid))
      temp = Object.values(temp.reduce((acc: any, cur: any) => ({...acc, [cur.uid]: cur}), {}))

      if (temp.length) {
        setTopNotifications({...topNotificationsRef.current, request: [...topNotificationsRef.current.request, ...temp]}) 
        topNotificationsRef.current.request = [...topNotificationsRef.current.request, ...temp]
      }
    }

    if (allActions.filter((action: {action: string, data: any}) => [ADD_BONUS_SCORE, TAKE_BONUS_SCORE, SEND_MESSAGE_TO_LK].includes(action.action)).length) {
      // обновить данные пользователя 
      apiClient.profileInfo.user().then(data => {
        let user = JSON.parse(localStorageWrap.getItem('user') || '{}')
        const updated = {...user, ...data}
        
        setUser(updated)
        localStorageWrap.setItem('user', JSON.stringify(updated))
      }).catch(e => {
        setUser(null)
      })
    }
  }

  return (
    <VisitorContext.Provider value={{visitorId, topNotifications, closeNotification, actionsController, closedTopNotifications}}>
      <AuthContextWrapper
        user={user}
        setUser={setUser}
      >
        <CartContextWrapper 
          cart={cart}
          setCart={setCart}
        >
          <ActionsPopup popupsData={popupsData} setPopusData={setPopusData} closedPopups={closedPopups} setClosedPopups={setClosedPopups}/>
          { children }
        </CartContextWrapper>
      </AuthContextWrapper>
    </VisitorContext.Provider>
  )
}

export function useVisitorContext() {
  return useContext(VisitorContext)
}

function ActionsPopup({popupsData, setPopusData, closedPopups, setClosedPopups}: any) {
  const [data, setData] = useState<any>(null)
  const navigate = useNavigate();

  useEffect(() => {
    if (!data) {
      checkNextPopup()
    }
  }, [popupsData])

  function checkNextPopup() {
    let temp: any

    if (data) {
      setClosedPopups((prev: any) => [...prev, data.uid])
      temp = Object.values(popupsData.reduce((acc: any, cur: any) => ({...acc, [cur.uid]: cur}), {})).filter((action: any) => ![...closedPopups, data.uid].includes(action.uid))
    } else {
      temp = Object.values(popupsData.reduce((acc: any, cur: any) => ({...acc, [cur.uid]: cur}), {})).filter((action: any) => !closedPopups.includes(action.uid))
    }


    if (temp.length) {
      setData(temp[0])
    } else {
      setData(null)
    }
  }

  return (
    <PopupActionSheet
      isActive={!!data}
      close={checkNextPopup}
      closeIcon
      classNamePopup=''
    >
      {
        !!data && 
        <div style={{ maxHeight: 'calc(80vh - 32px)' }} className='flex flex-col'>
          <div className='grow overflow-y-auto overflow-x-hidden no-scroll-bar'>
            {
              !!data?.title &&
              <div className='mb-5 text-[24px] text-dark font-bold dark:text-white'>
                {data?.title}
              </div>
            }

            {
              !!data?.image &&
              <img className='mb-5 rounded-[17px]' src={data.image} alt="" />
            }

            {
              !!data?.description &&
              <div 
                className='text-gray-50 dark:text-white [&_a]:text-main [&_ul]:list-disc [&_li]:ml-5 [&_ol]:list-decimal'
                dangerouslySetInnerHTML={{__html: data?.description}}
              />
            }
          </div>

          {
            !!data?.buttonText &&
            <div className='mt-5 xs:mb-0 mb-5'>
              {
                !!data?.buttonUrl ?
                <a 
                  style={{
                    color: data?.buttonTextColor,
                    background: data?.buttonColor,
                  }}
                  className='w-full h-10 flex items-center justify-center rounded-[18px] cursor-pointer fonts-bold' 
                  href={data?.buttonUrl} 
                  target='_blank'
                  onClick={checkNextPopup}
                >
                  {data?.buttonText}
                </a> : 
                <div
                  onClick={checkNextPopup}
                  style={{
                    color: data?.buttonTextColor,
                    background: data?.buttonColor,
                  }}
                  className='w-full h-10 flex items-center justify-center rounded-[18px] cursor-pointer fonts-bold' 
                >
                  {data?.buttonText}
                </div>
              }
            </div>
          }
        </div>
      }
    </PopupActionSheet>
  )
}

export function NotificationBlock({ place='header' }) {
  const { topNotifications, closeNotification, closedTopNotifications } = useVisitorContext()
  const [tempTopNotifications, setTempTopNotifications] = useState<Notification[]>([])

  useEffect(() => {
    console.log({topNotifications})
    if (topNotifications) {
      let allNotifications: Notification[] = Object.values(topNotifications).reduce((acc, cur) => [...acc, ...cur], [])
      let notClosedNotifications: Notification[] = allNotifications.filter((action) => !closedTopNotifications.includes(action.uid))
      let uniqueNotifications: Notification[] = Object.values(notClosedNotifications.reduce((acc, cur) => ({...acc, [cur.uid]: cur}), {}))
      console.log({uniqueNotifications})

      setTempTopNotifications(uniqueNotifications)
    } 
  }, [JSON.stringify(topNotifications)])

  function close(uid: string) {
    closeNotification(uid)
    setTempTopNotifications(Object.values(topNotifications).reduce((acc: any, cur: any) => [...acc, ...cur], []).filter((action: any) => ![...closedTopNotifications, uid].includes(action.uid)))
  }

  const {company} = useAppContext()
  const isLightTheme = company?.template?.theme !== 'dark';

  return (
    <div className='container'>
      {
        tempTopNotifications.filter((n: Notification) => n.place === place).map((notification: Notification) => (
          <div key={notification.uid} style={{ backgroundColor: notification.notificationColor }} className='relative shadow-md px-3 py-3 rounded-[10px] md:my-3 my-2 !h-auto'>
            <div className='relative flex xs:flex-row flex-col xs:justify-between  gap-x-2'>
              <div className='pr-3'>
                { notification.title && <h2 style={{ color: notification.notificationTextColor }} className={"font-medium xs:text-sm text-xs mb-1 "}>{ notification.title }  </h2> }
                { notification.text && <p style={{ color: notification.notificationTextColor }} className={"xs:text-sm text-xs "}>{ notification.text }</p> } 
              </div>
            
            <div className='flex flex-col justify-between items-end'>
              <div className='mr-[5px] xs:relative absolute right-0 top-0'>
                {notification.isClosable && <CloseIcon colorClassName={`${isLightTheme? 'fill-black' : 'fill-white'}`} onClick={() => close(notification.uid)} className='w-[10px] md:w-[12px] h-[10px] md:h-[12px] cursor-pointer'/>}
              </div>
              <div className='flex xs:flex-row flex-col items-end xs:mt-0 mt-1 mr-[5px]'>
                {notification?.buttonText && 
                  <a 
                    style={{
                      color: notification?.buttonTextColor,
                      borderBottomWidth: 2,
                      borderBottomColor: notification?.buttonColor,
                    }}
                    className='pb-[2px] h-fit whitespace-nowrap flex items-center justify-center cursor-pointer text-xs xs:text-sm' 
                    href={notification?.buttonUrl} 
                    target='_blank'
                  >
                    { notification?.buttonText }
                  </a>
                } 
              </div>
             
              
            </div>
            
             
            </div>
          </div>
        ))
      }
    </div>
  )
}


export interface Notification {
  buttonColor: string;
  buttonText: string;
  buttonTextColor: string;
  buttonUrl: string;
  isClosable: boolean;
  notificationColor: string;
  notificationTextColor: string;
  place: 'header' | 'footer' | 'main-top' | 'cart-top' | 'cart-bottom' | 'order-top' | 'order-bottom';
  text: string;
  title: string;
  uid: string;
}

// Все возможные акшены
const ATTACH_ORDER_TAG = 'attach-order-tag';
const DETACH_ORDER_TAG = 'detach-order-tag';
const ATTACH_CUSTOMER_TAG = 'attach-customer-tag';
const DETACH_CUSTOMER_TAG = 'detach-customer-tag';
const ADD_BONUS_SCORE = 'add-bonus-score';
const TAKE_BONUS_SCORE = 'take-bonus-score';
const SEND_TO_SENLER = 'send-to-senler';
const SEND_VK_MESSAGE = 'send-vk-message';
const SEND_VK_MESSAGE_TO_CUSTOMER = 'send-vk-message-to-customer';
const SEND_SMS = 'send-sms';
const SEND_SMS_TO_CUSTOMER = 'send-sms-to-customer';
const SEND_MOBILE_PUSH = 'send-mobile-push';
const SEND_TG_MESSAGE = 'send-tg-message';
const SEND_TG_MESSAGE_TO_CUSTOMER = 'send-tg-message-to-customer';
const SEND_MESSAGE_TO_LK = 'send-message-to-lk';
const SEND_HTTP_REQUEST = 'send-http-request';
const SHOW_POPUP = 'show-popup';
const SHOW_TOP_NOTIFICATION = 'shop-top-notification';
const ADD_GIFT_TO_CART = 'add-gift';
const ADD_GIFT_FOR_CHOOSE = 'add-gift-for-choose';
const RECOMMEND_GOODS = 'recommend-goods';
const APPLY_DISCOUNT = 'apply-discount';
const MAKE_FIXED_PRICE_ON_SET = 'make-fixed-price-on-set';
const MAKE_FREE = 'make-free';
const APPLY_DISCOUNT_TO_CHEAPEST = 'apply-discount-to-cheapest';
const APPLY_DISCOUNT_TO_MOST_EXPENSIVE = 'apply-discount-to-most-expensive';
const DISABLE_PROMO_CODES = 'disable-promo-codes';
const DISABLE_DISCOUNTS = 'disable-discounts';
const DISABLE_GIFT_FOR_CHOOSE = 'disable-gift-for-choose';
const DISABLE_FREE_DELIVERY = 'disable-free-delivery';
const SET_ORDER_LIMITATIONS = 'set-order-limitations';
const SET_PAYMENT_TYPES = 'set-payment-types';
const DISABLE_DISCOUNT_BY_BONUS = 'disable-discount-by-bonus';
const ADD_GOOD_TO_CART = 'add-good-to-cart';
const CALC_GOODS_COUNT_PROPERTY = 'calc-goods-count-property';
const CALC_GOODS_SUM_PROPERTY = 'calc-goods-sum-property';
const CALC_PROPERTY = 'calc-property';
const DISABLE_MAKING_ORDER = 'disable-making-order';
const SET_FRONTPAD_INTEGRATION_OPTIONS = 'set-frontpad-integrations-options';
const SET_PARTNER_INTEGRATION_OPTIONS = 'set-partner-integration-options';
const SET_IIKO_INTEGRATION_OPTIONS = 'set-iiko-integration-options';
const ADD_FIELD_TO_ORDER_FORM = 'add-field-to-order-form';
