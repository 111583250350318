import React from "react";
import Header from "../common/header/header";
import Footer from "../common/footer/footer";
import { NotificationBlock } from "../../contexts/VisitorContext";

import { ToastContainer } from 'react-toastify';
import Loader from "../pages/Loader";

type Props = {
  children: React.ReactNode,
  className?: string,
  header?: any,
}

export default function PrimaryLayout({children, className='', header=null}: Props) {
  return (
    <>
      <Loader />
      <div className={`flex flex-col min-h-screen`}>
        { header ? header : <Header /> }
        <main className={`flex-grow flex flex-col ${className}`}>
          <NotificationBlock place='header'/>
          {children}
        </main>
        <Footer />
      </div>
      <ToastContainer />
    </>
  );
}


        