import React, { useEffect, useMemo, useState } from 'react'

import Search from './Search'
import Categories from './Categories'
import Popular from './Popular'
import ProductCard from './ProductCard'
import { useAppContext } from '../../../../contexts/App'
import { apiClient } from '../../../../libs/api/apiClient'
import { SVGWrap } from '../../../common/util/SVGWrap'
import ProductCardModified from "./ProductCardModified";


export default function Catalog({
  catalog,
  tags,
  setCatalog,
  searchTags,
  setSearchTags,
  noSearchResult,
  searchData,
  initialCatalog,
  searchCategoriesWrapper,
  activeCategoryRef,
  activeCategoryIndex,
}: any) {
  const { company, branch } = useAppContext()

  const filterCatalogByTags = (id: number) => {
    const actuallyTags = searchTags.includes(id) ? searchTags.filter((tag: number) => tag !== id) : Array.from(new Set([...searchTags, id]))

    setSearchTags(actuallyTags)

    apiClient.catalog.get(branch.id, { byTags: actuallyTags, search: searchData.searchQuery }).then(({ data }: any) => {
      if (data) {
        setCatalog(data?.catalog || [])
      }
    })
  }

  return (
    <div className='mb-5 mt-5 md:mb-10 md:mt-10'>
      <CatalogCategoriesBlock
        searchData={ searchData }
        catalog={ catalog }
        setCatalog={ setCatalog }
        initialCatalog={ initialCatalog }
        searchCategoriesWrapper={ searchCategoriesWrapper }
        activeCategoryRef={ activeCategoryRef }
        activeCategoryIndex={ activeCategoryIndex }
      />

      <div className={`${searchData.searchQuery ? 'hidden' : 'opacity-100'} duration-200`}><Popular/></div>

      <div className={`flex gap-10 items-center w-full overflow-x-auto no-scroll-bar ${tags.length && 'mt-[25px]'}`}>
        {
          tags.map((tag: any) => (
            <div key={tag.id} className={`flex gap-3 items-center cursor-pointer w-max`} onClick={() => filterCatalogByTags(tag.id)}>
              <SVGWrap src={tag.icon} width={"16px"} height={"16px"} color={tag.color}/>
              <div className={`font-semibold whitespace-nowrap text-gray-50 dark:text-light`} style={searchTags.includes(tag.id) ? {color: tag.color} : {}}>{ tag.text }</div>
            </div>
          ))
        }
      </div>

      <div className={`min-h-[30vh] mt-5 ${ noSearchResult && "flex items-center justify-center"}`}>
        
        {noSearchResult && <div className='flex flex-row justify-center mt-[30px] dark:text-gray-10 xs:text-lg text-sm'>
            <p>Товары не найдены</p>
          </div>
        }

        {
          catalog.map((category: any) => {
            if (category.goods.length) {
              return (
                <div className='catalog-category mb-[35px] md:mb-10 ' key={category.id} id={`category_${category.id}`}>
                  <div className={`mb-5 text-2xl font-bold -tracking-[.01em] dark:text-white md:mb-10 md:text-4xl`}>
                    { category.title }
                  </div>
                  <div className={`grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 md+:grid-cols-4 gap-[10px] md:gap-[30px]`}>
                      {category.goods.map((productId: any, index: number) => (
                        <ProductCardModified
                          key={productId}
                          productId={productId}
                          tags={tags}
                          isLastProduct={category.goods.length - 1 == index}
                        />
                      ))}
                  </div>
                </div>
              )
            }
          })
        }
      </div>
    </div>
  )
}

function CatalogCategoriesBlock(props: any) {
  const {
    searchData,
    catalog,
    setCatalog,
    initialCatalog,
    searchCategoriesWrapper,
    activeCategoryRef,
    activeCategoryIndex
  } = props;

  return (
    <div className='relative flex items-center h-[40px]' ref={ searchCategoriesWrapper }>
      <Search
        className='absolute z-[100]'
        searchData={ searchData }
        setCatalog={ setCatalog }
        initialCatalog={ initialCatalog }
      />
      <Categories catalog={ catalog } activeCategoryRef={ activeCategoryRef }
                  activeCategoryIndex={ activeCategoryIndex }/>
    </div>
  )
}

