export default function StarRatingIcon() {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83834 0.725756C6.06285 0.0347729 7.04041 0.0347746 7.26492 0.725758L8.17936 3.5401C8.27976 3.84911 8.56773 4.05833 8.89265 4.05833H11.8518C12.5784 4.05833 12.8804 4.98805 12.2927 5.4151L9.89864 7.15445C9.63577 7.34544 9.52578 7.68396 9.62618 7.99298L10.5406 10.8073C10.7651 11.4983 9.97427 12.0729 9.38649 11.6458L6.99247 9.90649C6.7296 9.7155 6.37365 9.7155 6.11079 9.90649L3.71677 11.6458C3.12898 12.0729 2.33812 11.4983 2.56264 10.8073L3.47707 7.99298C3.57748 7.68396 3.46748 7.34544 3.20462 7.15445L0.810598 5.4151C0.222813 4.98804 0.524896 4.05833 1.25144 4.05833H4.21061C4.53553 4.05833 4.8235 3.84911 4.9239 3.5401L5.83834 0.725756Z"
        fill="#FFD000"
      />
    </svg>
  );
}
