import React, { useMemo } from "react";
import { useAppContext } from "../../../../contexts/App";
import { useWindowResize } from "../../../../hooks/useWindowResize";
import { useCartContext } from "../../../../contexts/CartContext";
import { useProductDetailCardContext } from "../../../../contexts/ProductDetailCardContext";
import { resizeImages } from "../../../../libs/helpers/resizeImage";
import { formatCurrency } from "../../../../libs/helpers/formatCurrency";
import { SVGWrap } from "../../../common/util/SVGWrap";
import CrossedOldPriceIcon from "../../../icons/CrossedOldPriceIcon";
import { useAnimationProductsCartContext } from "../../../../contexts/AnimationCartContext";
import Counter from "../../../common/button/Counter";
import productImgStub from "../../../../images/productImgStub.jpg";
import { ProductTag } from "../../../../pages/[city]";

type ProductCard = {
  productId: number;
  emptyImageClassName?: any;
  isLastProduct?: boolean;
  tags?: Array<ProductTag>;
};

export default function ProductCard({
  productId,
  emptyImageClassName,
  isLastProduct = false,
  tags = []
}: ProductCard) {
  const { company, address, branch, allProducts } = useAppContext()
  const isAdaptiveScreen = useWindowResize({ cvt: (w) => w > 520 && w < 768 });

  const {
    setNoAddressWarning,
    setTextInAddressAlert,
    setHeaderTextInAddressAlert
  } = useCartContext();
  const { openDetail } = useProductDetailCardContext();

  const product = useMemo(() => {
    let product = allProducts[productId];

    if (!product) {
      console.log(`Товар ${productId} не найден в allProducts`);
      return null;
    }

    return {
      ...product,
      imageResize: resizeImages(product?.image, [400, 600, 0], ["webp", "jpg"]),
      actuallyTags: tags.filter((tag: any) => product?.tags?.includes(tag.id)),
      priceString: formatCurrency(branch.extended.currency.code, product.price),
      oldPriceString: formatCurrency(
        branch.extended.currency.code,
        product.oldPrice
      )
    };
  }, [productId]);

  let orientationIsVertical =
    company.template.cartMobileOrientation === "vertical";

  if (!product) {
    return <></>;
  }

  return (
    <>
      <div
        onClick={() => openDetail(product.id)}
        className={`flex ${
          orientationIsVertical
            ? `md:my-[25px] flex-col rounded-[29px] bg-white p-[15px] shadow-md dark:bg-gray-50 xs:my-0`
            : `scale-100 gap-x-[20px] px-0 py-[25px] duration-200 xs:flex-col xs:rounded-[29px] xs:bg-white xs:p-[15px] xs:shadow-lg xs:hover:shadow-2xl xs:dark:bg-gray-50 md:hover:scale-[1.03] md:hover:transform md+:p-[12px] xl:p-[20px]`
        } cursor-pointer`}
      >
        <div className="relative overflow-hidden">
          <div
            className={
              "absolute z-[11] grid h-full w-full grid-cols-3 grid-rows-3 items-center justify-items-center xs:grid-cols-5 xs:grid-rows-5 xs:pt-1 md:grid-cols-4 lg:grid-cols-5 lg:gap-y-0 lg:pt-0 xl:grid-cols-6"
            }
          >
            {product.tags &&
              product.actuallyTags.map((tag: any, index: number) => (
                <div
                  key={index}
                  className="h-[25px] w-[25px] rounded-full bg-white xs:h-[32px] xs:w-[32px]"
                >
                  <div
                    className="flex h-[25px] w-[25px] items-center justify-center rounded-full xs:h-[32px] xs:w-[32px]"
                    style={{ backgroundColor: tag.background }}
                  >
                    <SVGWrap
                      width={"13px"}
                      height={"13px"}
                      src={tag.icon}
                      color={tag.color}
                    />
                  </div>
                </div>
              ))}
          </div>

          <div
            className={`relative overflow-hidden rounded-[17px] ${
              orientationIsVertical ? "w-full" : "w-[120px] xs:w-full"
            }`}
          >
            <img
              className={`${
                orientationIsVertical ? "w-full" : "z-[5] w-[120px] xs:w-full"
              } rounded-[17px] p-[0.5px] ${emptyImageClassName}`}
              src={productImgStub}
              alt=""
            />
            <div className={"absolute left-0 top-0 z-[10] h-full w-full"}>
              {product.image && (
                <picture style={{ height: "auto" }} className={`min-w-full`}>
                  <source
                    className={"w-full"}
                    type="image/webp"
                    srcSet={`${product.imageResize.webp[400]} 1x, ${product.imageResize.webp[600]} 2x, ${product.imageResize.webp[0]} 2.5x`}
                  />
                  <source
                    className={"w-full"}
                    type="image/jpeg"
                    srcSet={`${product.imageResize.jpg[400]} 1x, ${product.imageResize.jpg[600]} 2x, ${product.imageResize.jpg[0]} 2.5x`}
                  />
                  <img
                    loading={"lazy"}
                    decoding="async"
                    // @ts-ignore
                    fetchpriority="low"
                    style={{ contentVisibility: "auto" }}
                    className={`w-full border-dark duration-500`}
                    src={product.imageResize.jpg[400]}
                    srcSet={`${product.imageResize.jpg[600]} 2x, ${product.imageResize.jpg[0]} 2.5x`}
                    alt=""
                  />
                </picture>
              )}
            </div>
          </div>
        </div>

        <div
          className={`flex grow flex-col justify-between xs:w-full ${
            orientationIsVertical ? "w-full" : "w-[50%]"
          }`}
        >
          {isAdaptiveScreen && (
            <div className={`mt-[10px]`}>
              <div className="flex items-center gap-x-1 font-bold">
                <div
                  className={`flex ${
                    !!(
                      product?.skuGroupsValues.length || product?.optionsExists
                    )
                      ? "md+:text-[15px] md+:leading-[15px]"
                      : "md+:text-[17px] md+:leading-[17px]"
                  } text-xl leading-[24px] dark:text-main xl:text-xl xl:leading-[24px]`}
                >
                  {!!(
                    product?.skuGroupsValues.length || product?.optionsExists
                  ) && "От "}
                  {product.priceString}
                </div>
                {!!product.oldPrice && (
                  <div className="relative ml-[2px] inline-block text-sm leading-[17px] text-gray-50 dark:text-gray-30">
                    <div className="opacity-60">{product.oldPriceString}</div>
                    <div className="absolute inset-0 flex items-center">
                      <CrossedOldPriceIcon width="100%" height="100%" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            className={`${
              orientationIsVertical
                ? "mt-[25px] text-xl leading-[24px]"
                : isAdaptiveScreen
                  ? "mt-[10px]"
                  : "xs:mt-[25px]"
            } text-base font-bold -tracking-[.01em] dark:text-white`}
          >
            {product.title}
          </div>

          {!!(product.weight || product.size || product.volume) ? (
            <div
              className={`${
                orientationIsVertical
                  ? "mt-[9px]"
                  : isAdaptiveScreen
                    ? "mt-1"
                    : "mt-[2px] xs:mt-[9px]"
              } flex gap-2 text-sm font-bold -tracking-[.01em] text-gray-30 dark:text-gray-50 xs:text-base`}
            >
              {!!product.weight && <div>{product.weight} г.</div>}
              {!!product.size && (
                <div>
                  {product.size} {product.measure_unit}
                </div>
              )}
              {!!product.volume && <div>{product.volume} мл.</div>}
            </div>
          ) : (
            <div
              className={`${
                orientationIsVertical ? "h-[33px]" : "h-0 xs:h-[33px]"
              }`}
            ></div>
          )}

          <div
            className={`${
              isAdaptiveScreen
                ? "hidden"
                : orientationIsVertical
                  ? "text-sm"
                  : "text-xs xs:text-sm"
            } xs:overflow grow overflow-hidden leading-[17px] -tracking-[.01em] text-gray-50 dark:text-gray-30 lg:h-fit`}
          >
            <div className={"lines-ellipsis lines-ellipsis--2"}>
              {product.shortDescription}
            </div>
          </div>

          {/*--------------------V-------------------*/}
          <div
            className={`mt-[17px] ${
              orientationIsVertical ? "flex" : "hidden xs:flex"
            } min-h-[50px] items-center justify-between md+:min-h-[45px]`}
          >
            <div className={`${isAdaptiveScreen ? "hidden" : "font-bold"}`}>
              <div
                className={`flex ${
                  !!(product?.skuGroupsValues.length || product?.optionsExists)
                    ? "md+:text-[15px] md+:leading-[15px]"
                    : "md+:text-[17px] md+:leading-[17px]"
                } text-xl leading-[24px] dark:text-white xl:text-xl xl:leading-[24px]`}
              >
                {!!(
                  product?.skuGroupsValues.length || product?.optionsExists
                ) && "От "}
                {product.priceString}
              </div>
              {!!product.oldPrice && (
                <div className="relative ml-[2px] w-[73%] text-sm leading-[17px] text-gray-50 dark:text-gray-30">
                  <div className="flex items-center opacity-60">
                    {product.oldPriceString}
                  </div>
                  <div className="absolute left-0 top-0 h-full w-full">
                    <CrossedOldPriceIcon width={"100%"} height={"auto"} />
                  </div>
                </div>
              )}
            </div>

            {!!(
              (product?.canBuyForFree && !product?.price) ||
              product?.price
            ) && (
              <>
                {!!(
                  product?.skuGroupsValues.length ||
                  product?.optionsExists ||
                  (!product.canBuyForFree && product.price === 0)
                ) ? (
                  <div
                    onClick={(event) => {
                      event.stopPropagation();
                      if (address?.point == null) {
                        setTextInAddressAlert(
                          "Чтобы добавить блюдо в корзину нужно указать адрес. Хотим убедиться, что вы в зоне доставки."
                        );
                        setHeaderTextInAddressAlert("Какой у вас адрес?");
                        setNoAddressWarning("toast");
                      } else {
                        openDetail(product.id);
                      }
                    }}
                    className={`flex h-[35px] ${isAdaptiveScreen ? "w-full" : "w-[110px] md+:w-[100px] lg:w-[110px]"} cursor-pointer items-center justify-center rounded-[18px] bg-orderbtn text-base font-bold -tracking-[.01em] text-main duration-500 hover:bg-main hover:text-white`}
                  >
                    Выбрать
                  </div>
                ) : (
                  <ActionBlock
                    isAdaptiveScreen={isAdaptiveScreen}
                    product={product}
                    orientationIsVertical={true}
                  />
                )}
              </>
            )}
          </div>

          {/*------------------H-----------------*/}
          {!!(
            (product?.canBuyForFree && !product?.price) ||
            product?.price
          ) && (
            <div
              className={`${
                orientationIsVertical ? "hidden" : "flex xs:hidden"
              } ${
                product.weight || product.size || product.volume
                  ? ""
                  : "mt-[10px]"
              } items-center gap-3`}
            >
              {!!(
                product?.skuGroupsValues.length ||
                product?.optionsExists ||
                (!product.canBuyForFree && product.price === 0)
              ) ? (
                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    if (address?.point == null) {
                      setTextInAddressAlert(
                        "Чтобы добавить блюдо в корзину нужно указать адрес. Хотим убедиться, что вы в зоне доставки."
                      );
                      setHeaderTextInAddressAlert("Какой у вас адрес?");
                      setNoAddressWarning("toast");
                    } else {
                      openDetail(product.id);
                    }
                  }}
                  className="flex min-w-[110px] cursor-pointer items-center justify-center rounded-[18px] bg-main py-[4px] font-bold -tracking-[.01em] text-white duration-500 hover:bg-main hover:text-white"
                >
                  От <p className="ml-1">{product.priceString}</p>
                </div>
              ) : (
                <ActionBlock product={product} orientationIsVertical={false} />
              )}

              {(product?.skuGroupsValues.length || product?.optionsExists) &&
                !!product.oldPrice && (
                  <div className="relative w-min font-bold text-gray-50 dark:text-gray-30">
                    <div className="flex items-center gap-1 opacity-60">
                      {product.oldPriceString}
                    </div>
                    <div className="absolute left-0 top-0 h-full w-full">
                      <CrossedOldPriceIcon width={"100%"} height={"auto"} />
                    </div>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>

      {/* {!isLastProduct && (
        <div className="min-h-[2px] bg-gradient-to-r from-gray-20/20 via-gray-20 to-gray-20/20 dark:from-gray-50/50 dark:via-gray-40 dark:to-gray-50/50 xs:hidden"></div>
      )} */}
    </>
  );
}

function ActionBlock({
  isAdaptiveScreen = false,
  product,
  orientationIsVertical = false
}: any) {
  const { address } = useAppContext();
  const { addProductToAnimation, removeProductFromAnimation } = useAnimationProductsCartContext();
  const { cart, addProduct, getProductCart, updateProduct } = useCartContext();

  const cartProduct = useMemo(() => {
    return getProductCart(product.id, []);
  }, [cart.raw, product.id]);

  if (orientationIsVertical) {
    return (
      <>
        {!!cartProduct?.count ? (
          <Counter
            count={cartProduct.count}
            add={() => {
              if (address) {
                addProductToAnimation(product.id);
              }

              updateProduct(cartProduct.uid, { count: cartProduct.count + 1 });
            }}
            remove={() => {
              removeProductFromAnimation(product.id);

              updateProduct(cartProduct.uid, {
                count:
                  product.minCount && product.minCount == cartProduct.count
                    ? 0
                    : cartProduct.count - 1
              });
            }}
            disabledAdd={
              product.stockBalance && cartProduct.count >= product.stockBalance
            }
            className={isAdaptiveScreen ? "w-full" : ""}
          />
        ) : (
          <div
            onClick={(event) => {
              event.stopPropagation();
              console.log(address);
              if (address) {
                addProductToAnimation(product.id);
              }
              addProduct(product.id, [], product.minCount);
            }}
            className={`flex h-[35px] ${isAdaptiveScreen ? "w-full" : "w-[110px]"} cursor-pointer items-center justify-center rounded-[18px] bg-orderbtn text-base font-bold -tracking-[.01em] text-main duration-500 hover:bg-main hover:text-white`}
          >
            В корзину
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {!!cartProduct?.count ? (
        <Counter
          count={cartProduct.count}
          add={() => {
            if (address) {
              addProductToAnimation(product.id);
            }

            updateProduct(cartProduct.uid, { count: cartProduct.count + 1 });
          }}
          remove={() => {
            removeProductFromAnimation(product.id);

            updateProduct(cartProduct.uid, {
              count:
                product.minCount && product.minCount == cartProduct.count
                  ? 0
                  : cartProduct.count - 1
            });
          }}
          disabledAdd={
            product.stockBalance && cartProduct.count >= product.stockBalance
          }
        />
      ) : (
        <div className={"flex flex-row items-center gap-x-3"}>
          <div
            onClick={(event) => {
              event.stopPropagation();
              addProduct(product.id, [], product.minCount);
              console.log(address);
              if (address) {
                addProductToAnimation(product.id);
              }
            }}
            className="flex !w-[110px] cursor-pointer items-center justify-center gap-1 rounded-[18px] bg-main px-[15px] py-[4px] font-bold -tracking-[.01em] text-white"
          >
            {product.priceString}
          </div>
          {!!product.oldPrice && (
            <div className="relative w-min font-bold text-gray-50 dark:text-gray-30">
              <div className="flex items-center gap-1 opacity-60">
                {product.oldPriceString}
              </div>
              <div className="absolute left-0 top-0 h-full w-full">
                <CrossedOldPriceIcon width={"100%"} height={"auto"} />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
