import { useEffect, useState } from "react";
import mainStock from "../../images/mainStock.jpg";
import time from "../../images/time.svg";
import { useCartContext } from "../../contexts/CartContext";
import { useAuthContext } from "../../contexts/AuthContext";
import { useAppContext } from "../../contexts/App";
import { sendEvent, useVisitorContext } from "../../contexts/VisitorContext";
import { useUpdatePage } from "../../hooks/useUpdatePage";
import { apiClient } from "../../libs/api/apiClient";
import DetailStock from "../../components/pages/stocksPage/detailStock";
import { findDiffDate } from "../../libs/helpers/findDiffDate";
import { Preloader } from "../../components/common/preloader";
import { BaseButton } from "../../components/common/button/BaseButton";
import { NotStockImage } from "../../components/icons/notStockImage";
import { useSearchParams } from "react-router-dom";
import { animateScroll } from "react-scroll";


interface IButtonsStock{
  url: string,
  title: string
}

interface IStock {
  buttons: IButtonsStock[] | null,
  description: string,
  endAt: string,
  id: number,
  image: string | null,
  promoCode: string
  shortDescription: string
  title: string,
}

export default function Stock() {
  const { cart, loading, updateCart } = useCartContext()
  const { user } = useAuthContext()
  const { company, branch } = useAppContext();

  const [stocks, setStocks] = useState<IStock[]>([]);
  const [additionalInfo, setAdditionalInfo]= useState<{description: string} | null>(null)
  const [fetching, setFetching] = useState(true);
  const [choosedStockId, setChoosedStockId] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { visitorId } = useVisitorContext()


  useEffect(() => {
    if (visitorId) {
      sendEvent('section-visited', {target: 'Акции'})
    }
  }, [visitorId])

  useEffect(() => {
    animateScroll.scrollToTop()
  }, [])

  useUpdatePage(
    () => {
      apiClient.stocks.get(branch.id).then((response) => {
        setAdditionalInfo(response.additional)
        setStocks(response.data)
        setFetching(false)
      })
    }, {interval: 1000 * 60 * 60, deps: [branch.id]}
  )

  useEffect(() => {
//    setCurrentPage(1);
    setFetching(true);
    setStocks([])
  }, [user?.phone_confirmed, user?.token])

  const handlerOpenModal = (stock: IStock) => {
    setSearchParams(params => {
      params.set("stock", stock.id + '');
      return params;
    });

    setIsActive(true)
  };

  const handlerCloseModal = () => {
    setSearchParams(params => {
      params.delete("stock");
      return params;
    });
    setIsActive(false)
  };

  useEffect(() => {
    let stockInQuery = searchParams.get('stock')
    if (stockInQuery) {
      setChoosedStockId(stockInQuery)
    } else {
      setChoosedStockId(null)
    }

  }, [searchParams])

  const [isActive, setIsActive] = useState(false)

  return (
    <div className="container">
      {choosedStockId && (
        <DetailStock
          isActive={isActive}
          close={handlerCloseModal}
          stockId={choosedStockId}
          stocks={stocks}
        />
      )}

      <div className="mb-5 mt-5 md:mb-[30px] md:mt-10">
        <h2 className="text-2xl font-bold md:text-4xl text-gray-60 dark:text-white">Акции</h2>
      </div>

      {
        !!additionalInfo?.description && (
          <div className="mb-[30px] sm:mb-[40px]">
            <p className="break-words text-sm text-gray-50 dark:text-white md:text-base">
              {additionalInfo?.description}
            </p>
          </div>
        )
      }

      <div className="mb-[50px] grid w-[100%] gap-[30px] sm:grid-cols-2 xl:grid-cols-3 xl:grid-cols-[repeat(auto-fit,minmax(376px,1fr))]">
        {fetching ? 
          <div className={"flex w-full justify-center pt-[20vh]"}>
            {" "}
            <Preloader countOfDot={4} className="mb-[100px] gap-2" />{" "}
          </div>
          : stocks.length > 0
              ? stocks.map((stock: IStock, index: number) => {
                const { days, hours, mins } = findDiffDate(stock.endAt);
                const bgColor = days || hours > 2 ? "additional" : "main";
                return (
                  <div
                    key={index}
                    className="mb-[20px] flex w-[100%] flex-col justify-between overflow-hidden rounded-[29px] bg-white p-[15px] shadow-md dark:bg-gray-50"
                  >
                    <div>
                      <div className="relative w-[100%]">
                        {stock.endAt && (
                          <div
                            className={`absolute bg-${bgColor} left-[15px] top-[15px] w-max rounded-2xl px-[13px] py-[6px] text-xs text-white`}
                          >
                            <span className="flex items-center">
                              До конца действия:
                              <img className="mx-[6px]" src={time} />
                              <span>
                                {days ? `${days} д ${hours} ч` : null}
                                {!days && hours ? `${hours} ч` : null}
                                {!days && !hours && mins ? `${mins} мин` : null}
                                {!days && !hours && !mins
                                  ? `Меньше минуты`
                                  : null}
                              </span>
                            </span>
                          </div>
                        )}
                        {stock.image ? (
                          <img
                            src={stock.image}
                            onClick={() => handlerOpenModal(stock)}
                            className="cursor-pointer rounded-2xl"
                          />
                        ) : (
                          <img
                            onClick={() => handlerOpenModal(stock)}
                            className="cursor-pointer rounded-2xl"
                            src={mainStock}
                            alt=""
                          />
                        )}
                      </div>

                      <div>
                        <h3
                          className="mt-[25px] cursor-pointer text-xl font-medium text-gray-60 dark:text-white md:text-2xl"
                          onClick={() => handlerOpenModal(stock)}
                        >
                          {stock.title}
                        </h3>
                        <p className="mt-[15px] text-gray-50 dark:text-white">
                          {stock.shortDescription}
                        </p>
                      </div>
                    </div>

                    <div className="justify-end">
                      <div className="mt-[30px] flex flex-col justify-between sm:flex-row">
                        <BaseButton
                          onClick={() => handlerOpenModal(stock)}
                          className="mb-[20px] w-[100%] border border-solid border-main text-base font-bold text-main hover:bg-main hover:text-white sm:mb-[0] sm:w-[45%]"
                        >
                          Подробнее
                        </BaseButton>

                        {stock.promoCode &&
                          branch.order.fields.promoCode?.isUsed && (
                            <>
                              {stock.promoCode.toLowerCase() !==
                              cart.cartPromoCode.promoCode?.code?.toLowerCase() ? (
                                <BaseButton
                                  onClick={() =>
                                    !loading &&
                                    updateCart({
                                      promoCode: stock.promoCode,
                                      raw: cart.raw
                                    })
                                  }
                                  className="w-[100%] bg-main text-base font-bold text-white hover:opacity-80 sm:w-[45%]"
                                >
                                  Промокод
                                </BaseButton>
                              ) : (
                                <BaseButton
                                  disabled={true}
                                  className="w-[100%] bg-main text-base font-bold text-white sm:w-[45%]"
                                >
                                  Применён
                                </BaseButton>
                              )}
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                );
              })
              : 
              <div
                className={
                  "flex h-full min-h-[50vh] w-full flex-col items-center justify-center"
                }
              >
                {/*хз че засунуть но без этого грустно*/}
                <div className={"mb-[5vh]"}>
                  <NotStockImage />
                </div>
                <p className={"text-2xl font-bold text-gray-60 dark:text-white"}>
                  Совсем скоро
                </p>
                <p className={"text-2xl font-bold text-main"}>
                  здесь появятся самые
                </p>
                <p className={"text-2xl font-bold text-main"}>вкусные акции</p>
              </div>
        }
      </div>
    </div>
  );
}