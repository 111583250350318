import React, {useId, useEffect} from "react";

interface ICheckbox {
  className?: string;
  labelClassName?: string;
  label?: any;
  name?: string;
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  errors?: any;
}

export default function Checkbox({
  className = '', 
  labelClassName= '', 
  label='', 
  name='', 
  value, 
  onChange, 
  disabled = false,
  errors
}: ICheckbox) {
  const id = useId();

  const handleChange = ({target}: any) => {
    onChange(target.checked)
  }

  const error = errors[name]; 

  useEffect(() => {
    delete errors[name];
  }, [value]);

  return (
    <div className={`${className}`}>
      <div className={`flex items-center cursor-pointer`}>
        <input type="checkbox" id={id} checked={value} name={name} onChange={handleChange} disabled={disabled} className={'hidden'}/>

        <label
          htmlFor={id}
          className={`flex items-center gap-4 text-sm ${error ? 'text-yellow' : 'text-gray-60 dark:text-white'} font-normal cursor-pointer ${labelClassName}`}
        >
          {
            value ? 
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path className="fill-main" d="M6.22222 12.4444L1.77778 8L3.03111 6.73778L6.22222 9.92889L12.9689 3.18222L14.2222 4.44444L6.22222 12.4444ZM14.2222 0H1.77778C0.791111 0 0 0.791111 0 1.77778V14.2222C0 14.6937 0.187301 15.1459 0.520699 15.4793C0.854097 15.8127 1.30628 16 1.77778 16H14.2222C14.6937 16 15.1459 15.8127 15.4793 15.4793C15.8127 15.1459 16 14.6937 16 14.2222V1.77778C16 0.791111 15.2 0 14.2222 0Z"/>
            </svg>
            : <div className="w-4 h-4 border-[1px] border-main rounded-[2px]"></div>
          }
          {label}
        </label>
      </div>
    </div>
  )
}
