import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import { useAppContext } from "./App";
import { useAuthContext } from "./AuthContext";
import useSelectCityAndBranch from "../libs/helpers/selectCityAndBranch";
import { useCartContext } from "./CartContext";
import { apiClient } from "../libs/api/apiClient";
import { localStorageWrap } from "../libs/helpers/localStorageWrap";
import { IDeliveryAddress, ISaveDeliveryAddressRequest, ISaveDeliveryAddressResponse, ISaveDeliveryAddressResponseData } from "../types/address";


interface IAddressContext {
  allUserAddresses: any[],
  setAllUserAddresses: (v: any[]) => void,
  fetchAllUserAddresses: () => Promise<void>,
  changeBranchPopupCallback: ((v: boolean) => void) | null,
  addressError: {message: string, type: string} | null,
  trySaveDeliveryPoint: (point: any, addressFields?: any) => any,
  trySavePickupPoint: (city: any, point: any) => Promise<any>,
  setAddressError: (v: {message: string, type: string} | null) => void,
  addressRef: React.MutableRefObject<any>,
  deletedProducts: any[],
  successSelectCallback: () => void;
  setSuccessSelectCallback: (f: () => void) => void;
}

const AddressContext = createContext<IAddressContext>({
  trySaveDeliveryPoint: () => Promise.resolve(false),
  trySavePickupPoint: () => Promise.resolve(false),
  allUserAddresses: [],
  setAllUserAddresses: () => {},
  fetchAllUserAddresses: () => Promise.resolve(),
  changeBranchPopupCallback: null,
  addressError: null,
  setAddressError: () => {},
  addressRef: { current: null },
  deletedProducts: [],
  successSelectCallback: () => {},
  setSuccessSelectCallback: () => {},
})

type Props = {
  children: React.ReactNode,
}

export function AddressContextWrapper ({children}: Props) {
  const { city, company, branch, address, setAddress, allProducts } = useAppContext();
  const { user } = useAuthContext()
  const selectCityAndBranch = useSelectCityAndBranch()
  const {cart, updateCart, synchronize, updateDataCart} = useCartContext()

  const [allUserAddresses, setAllUserAddresses] = useState<any[]>([])
  const [changeBranchPopupCallback, setChangeBranchPopupCallback] = useState<((v: boolean) => void) | null>(null)
  const [addressError, setAddressError] = useState<{message: string, type: string} | null>(null)
  const [successSelectCallback, setSuccessSelectCallback] = useState(() => () => {})
  const addressRef = useRef<any>()
  addressRef.current = address

  const fetchAllUserAddresses = async () => {
    if (user == null || branch?.id == null ) {
      setAllUserAddresses([])
      return;
    }
    try {
      await apiClient.profileAddress.addresses(branch?.id).then(({data}) => {
        setAllUserAddresses(data || [])
      })
    } catch(e) {}
  }

  useEffect(() => {
    fetchAllUserAddresses().then()
  }, [user?.token, branch?.id])

  const savePickupPoint = async (newCity: any, newPickupPoint: any) : Promise<any> => {
    const point = {...newPickupPoint, city: newCity.slug, deliveryZone: null}
    
    let res = await updateCart({
      deliveryType: 'pickup',
      pickupPointId: newPickupPoint.id,
      deliveryCity: newCity.guid,
    })

    if (res.status !== 200) {
      setAddressError({ type: "error", message: 'Что-то пошло не так, попробуйте еще раз' }); 
      return false
    }
    
    localStorageWrap.setItem('address', JSON.stringify({type: 'pickup', point}))
    setAddress(addressRef.current = {type: 'pickup', point})

    return selectCityAndBranch(newCity.slug, newPickupPoint.branchId || branch?.id)
  }

  const saveDeliveryPointLocally = async (citySlug: string, point: any, isCityOrBranchChanged = true) => {
    const addr = { type: 'delivery', point: { ...point, city: citySlug, deliveryZone: null } }
    localStorageWrap.setItem('address', JSON.stringify(addr))
    setAddress(addressRef.current = addr)

    if (! isCityOrBranchChanged) {
      return true;
    }

    await selectCityAndBranch(citySlug, point.branchId || branch?.id);

    return true;
  }

  const [deletedProducts, setDeletedProducts] = useState<any[]>([])

  const getDeletedProducts = (newBranchId: number) : Promise<any[]> => {
    if (!cart.cartId) return Promise.resolve([])
    return apiClient.cart.willBeDeleted(newBranchId, cart.cartId).then((data) => {
      if (data?.products) {
        const result = Object.values(data.products).map((c : any) => {
          let productData: any = cart.raw.find((productInCart: any) => {
            return productInCart.productId === c.productId;
          })
          let productInfo = allProducts[productData.productId]
          return {...productData, ...productInfo}
        })
        setDeletedProducts(result)
        return result
      }
      return []
    }).catch(() => [])
  }

  //------------------------check delivery zone------------------------------
  const sanitizeAddressFields = (f: any) => {
    let fields: any = {}
    f?.floor && (fields.floor = f.floor)
    f?.flat && (fields.flat = f.flat)
    f?.entrance && (fields.entrance = f.entrance)
    f?.id && (fields.id = f.id)
    return fields
  }

  const trySaveDeliveryPoint = async (deliveryPoint: IDeliveryAddress, addressFields: any = {}) => {
    addressFields = sanitizeAddressFields(addressFields)
    console.log({deliveryPoint})

    if (! deliveryPoint) {
      return Promise.resolve(false);
    }

    if (! deliveryPoint?.house) {
      setAddressError({ type: 'message', message: 'Пожалуйста, укажите номер дома' })

      return Promise.resolve('notHouse');
    }

    await synchronize();

    // Запрос на навый api с параметрами
    const body: ISaveDeliveryAddressRequest = {
      cityId: city.id,
      cartId: cart.cartId ?? '',
      customerAddress: {
        address: deliveryPoint,
        ...addressFields
      }
    }

    // варианты что может вернуться:
    // 0. 404 - зона доставки не найдена
    // 1. Просто сменили зону и ничего не изменилось больше. Вернётся {data: {cart, isBranchChanged: false, isCityChanged: false}} (не нужно выводить попап с подтверждением удаления товаров и точка уже сохранена заменяем корзину и готово)
    // 2. Сменили зону и сменился филиал и/или город, но товаров в корзине не было или никакой товар удален не будет. Вернется {data: {cart, isBranchChanged, isCityChanged, slug}} (не нужно выводить попап с подтверждением удаления товаров и точка уже сохранена заменяем корзину, филиал и город и готово)
    // 3. Тоже что в п. 2, но какие-то товары будут удалены. Вернется {data: {items, isBranchChanged, isCityChanged, slug}}

    // @ts-ignore
    return apiClient.delivery.saveDeliveryAddress(branch.id, { ...body, isConfirm: false })
      .then(({ data, status }: ISaveDeliveryAddressResponse) => {

        if (status === 404) {
          setAddressError({ type: "notZone", message: 'Выбранный адрес не входит ни в одну зону доставки' });

          return new Promise(resolve => resolve(404));
        }

        if (status !== 200) {
          setAddressError({ type: "error", message: 'Что-то пошло не так, попробуйте еще раз' });

          return 500;
        }

        const getSlugAngPoint = (data: any) => {
          const citySlug = data?.slug || city.slug

          const pointToSave: any = {
            ...deliveryPoint,
            branchId: data?.cart?.branchId,
            city: citySlug
          }

          if (data?.cart?.deliveryZoneId) {
            pointToSave.deliveryZoneId = data.cart.deliveryZoneId
          } else {
            pointToSave.notZone = true
          }

          if (data?.customerAddress?.id) {
            pointToSave.addressId = data.customerAddress.id
          }

          return { citySlug, pointToSave }
        }

        const finishSaveDeliveryAddress = (data: ISaveDeliveryAddressResponseData, isCityOrBranchChanged = true) => {
          const { citySlug, pointToSave } = getSlugAngPoint(data)

          updateDataCart(data.cart)

          return saveDeliveryPointLocally(citySlug, pointToSave, isCityOrBranchChanged)
        }

        const { isBranchChanged, isCityChanged } = data;

        if (! (isCityChanged || isBranchChanged)) {
          return finishSaveDeliveryAddress(data, false);
        }

        if (! data?.items) {
          return finishSaveDeliveryAddress(data, (isCityChanged || isBranchChanged));
        }

        setDeletedProducts(data.items)

        return new Promise<boolean>(resolve => {
          setChangeBranchPopupCallback(() => (confirmed: boolean) => {
            if (! confirmed) {
              setChangeBranchPopupCallback(null);
              resolve(false);

              return;
            }

            apiClient.delivery.saveDeliveryAddress(branch.id, { ...body, isConfirm: true })
              .then(({ data, status }: ISaveDeliveryAddressResponse) => {
                if (status !== 200) {
                  resolve(false);
                  return;
                }

                finishSaveDeliveryAddress(data)
                  .then((isSaved) => {
                    setChangeBranchPopupCallback(null);
                    resolve(isSaved);
                  });
              });
          });
        });
      });
  }

  //----------------------------------------try save pickup point
  const trySavePickupPoint = async (newCity: any, newPickupPoint: any) => {
    await synchronize()

    if (branch.id !== newPickupPoint.branchId) {
      let deletedProducts = await getDeletedProducts(newPickupPoint.branchId)
      if (deletedProducts.length > 0) {
        return new Promise<boolean>(resolve => {
          setChangeBranchPopupCallback(() => (v: boolean) => {
            if (v) {
              savePickupPoint(newCity, newPickupPoint).then(() => {
                setChangeBranchPopupCallback(null)
                resolve(true)
              })
            } else {
              setChangeBranchPopupCallback(null)
              resolve(false)
            }
          })
        })
      } else {
        return await savePickupPoint(newCity, newPickupPoint)
      }
    } else {
      return await savePickupPoint(newCity, newPickupPoint)
    }
  }

  return (
    <AddressContext.Provider value={{
      trySavePickupPoint, trySaveDeliveryPoint,
      allUserAddresses, setAllUserAddresses, fetchAllUserAddresses,
      addressError, setAddressError,
      changeBranchPopupCallback,
      addressRef,
      deletedProducts,
      successSelectCallback, setSuccessSelectCallback}}>
      { children }
    </AddressContext.Provider>
  )

}

export function useAddressContext() {
  return useContext(AddressContext)
}
