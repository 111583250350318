"use client";

import { useEffect } from "react";
import { useAppContext } from "../../../contexts/App";

const apiKey = process.env.NEXT_PUBLIC_YMAPS_API_KEY;


type YandexMapProps = {
  setRating: React.Dispatch<React.SetStateAction<number>>;
};

const YandexMapRating = ({ setRating }: YandexMapProps) => {
  const { company } = useAppContext()
  //console.debug(company);
  useEffect(() => {
    const loadYandexMaps = () => {
      const script = document.createElement("script");
      script.src = `https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=${apiKey}`;
      script.type = "text/javascript";
      script.onload = () => {
        if (window.ymaps) {
          window.ymaps.ready(init);
        }
      };
      document.head.appendChild(script);
    };

    const init = () => {
      window.ymaps
        // @ts-ignore 
        .findOrganization(`${company?.organizationYandexId || 0}`)
        .then(function (orgGeoObject: any) {
          const rating = orgGeoObject.properties.get("rating");
          setRating(rating.score);
        });
    };

    loadYandexMaps();
  }, [setRating]);

  return null;
};

export default YandexMapRating;
