import { useEffect, useRef } from "react";

interface IGlobalMessage {
  children: any,
  className?: string,
  style?: any;
}

export default function GlobalMessage({children, className, style}:IGlobalMessage) {
  return (
    <div id="global-message" style={style} className={`min-h-[60px] xs:min-h-[50px] xs:w-full bg-main flex flex-row shadow-[0px_4px_22px_-6px_rgba(6,5,50,0.15)] gap-x-6 justify-between items-center px-[18px] xs:justify-center ${className}`}>
      {children}
    </div>
  )
}
