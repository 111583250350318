export default function VkRoundIcon({
  width = "62",
  height = "62"
}: {
  width?: string;
  height?: string;
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 62 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.000152588" width="62" height="62" rx="31" className='fill-main' />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.1375 40.3551C32.1375 40.3551 32.7212 40.2888 33.0202 39.9604C33.2939 39.6595 33.2844 39.0917 33.2844 39.0917C33.2844 39.0917 33.248 36.4403 34.4521 36.0487C35.6387 35.6637 37.1622 38.6128 38.7792 39.7469C40.0006 40.6043 40.9277 40.4166 40.9277 40.4166L45.2486 40.3551C45.2486 40.3551 47.5079 40.2127 46.4367 38.3961C46.3481 38.2472 45.8117 37.0518 43.225 34.5959C40.5148 32.0254 40.8787 32.4411 44.141 27.9941C46.1283 25.2859 46.9224 23.6326 46.674 22.9258C46.4384 22.2495 44.9764 22.429 44.9764 22.429L40.113 22.4598C40.113 22.4598 39.7522 22.4097 39.4848 22.573C39.2238 22.7332 39.0545 23.1069 39.0545 23.1069C39.0545 23.1069 38.2856 25.2018 37.2588 26.9845C35.0929 30.7442 34.2274 30.9431 33.873 30.7103C33.0488 30.1651 33.2543 28.5231 33.2543 27.3566C33.2543 23.712 33.7955 22.1929 32.2023 21.7998C31.6738 21.6688 31.2847 21.583 29.9319 21.5685C28.1963 21.5507 26.728 21.5749 25.8958 21.9907C25.3421 22.2673 24.9149 22.8853 25.176 22.9209C25.4972 22.9645 26.225 23.1215 26.611 23.6585C27.1094 24.3526 27.0919 25.9088 27.0919 25.9088C27.0919 25.9088 27.3784 30.199 26.4228 30.7312C25.7677 31.0968 24.869 30.3511 22.9373 26.9393C21.9485 25.1922 21.2017 23.2606 21.2017 23.2606C21.2017 23.2606 21.0577 22.8999 20.7998 22.7057C20.4882 22.4712 20.053 22.3984 20.053 22.3984L15.4317 22.429C15.4317 22.429 14.7371 22.4485 14.4824 22.7574C14.2562 23.0308 14.4649 23.5987 14.4649 23.5987C14.4649 23.5987 18.0833 32.2551 22.181 36.6182C25.9385 40.6172 30.204 40.3551 30.204 40.3551H32.1375Z"
        fill="white"
      />
    </svg>
  );
}
