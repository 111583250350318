import React from 'react'

export default function WarningBlock({children}: any) {
  return (
    <div className='w-full py-[10px] px-[31px] flex items-center justify-center gap-[10px] bg-[#FF8A00] rounded-[10px]'>
      <svg width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.86 1.97129L23.9013 15.6126C25.0569 17.5719 24.4893 20.1495 22.6329 21.3693C22.0098 21.7802 21.2835 21.9992 20.5413 22H4.45761C2.27241 22 0.5 20.13 0.5 17.8212C0.5 17.0402 0.707601 16.2763 1.0976 15.6126L9.14002 1.97129C10.2944 0.0120492 12.7352 -0.588067 14.5916 0.631721C15.1052 0.969057 15.5396 1.42739 15.86 1.97129ZM12.5 17.1111C12.8183 17.1111 13.1235 16.9823 13.3486 16.7531C13.5736 16.5239 13.7 16.213 13.7 15.8888C13.7 15.5647 13.5736 15.2538 13.3486 15.0246C13.1235 14.7954 12.8183 14.6666 12.5 14.6666C12.1818 14.6666 11.8765 14.7954 11.6515 15.0246C11.4265 15.2538 11.3 15.5647 11.3 15.8888C11.3 16.213 11.4265 16.5239 11.6515 16.7531C11.8765 16.9823 12.1818 17.1111 12.5 17.1111ZM12.5 6.11099C12.1818 6.11099 11.8765 6.23976 11.6515 6.46897C11.4265 6.69818 11.3 7.00906 11.3 7.33322V12.2221C11.3 12.5463 11.4265 12.8572 11.6515 13.0864C11.8765 13.3156 12.1818 13.4444 12.5 13.4444C12.8183 13.4444 13.1235 13.3156 13.3486 13.0864C13.5736 12.8572 13.7 12.5463 13.7 12.2221V7.33322C13.7 7.00906 13.5736 6.69818 13.3486 6.46897C13.1235 6.23976 12.8183 6.11099 12.5 6.11099Z" fill="white"/>
      </svg>

      <div className='text-[20px] font-bold text-[#fff] max-w-[480px]'>{ children }</div>
    </div>
  )
}
